import { defineStore } from 'pinia'
import useSessionStore from './session'

const useNavigationStore = defineStore('navigation', () => {
    const canAccessTo = (route: {
        params: {
            idOrga: string | undefined
            idBU: string | undefined
        }
    }) => {
        return new Promise((resolve) => {
            const { idOrga, idBU } = route.params
            if (!idOrga) {
                return resolve(true)
            }

            const organizations = useSessionStore().getOrganizationsSession

            const organization = organizations.filter(
                (orga: any) => Number(orga.id) === Number(idOrga)
            )
            const hasOrganization = organization.length === 1
            if (!hasOrganization) {
                return resolve(false)
            }
            if (!idBU) {
                return resolve(true)
            }
            const bus = organization[0].businessUnits
            const hasBus = bus.length > 0
            if (!hasBus) {
                return resolve(false)
            }
            return resolve(
                bus.filter((bu: any) => Number(bu.id) === Number(idBU))
                    .length === 1
            )
        })
    }

    return {
        canAccessTo
    }
})

type NavigationStore = Omit<
    ReturnType<typeof useNavigationStore>,
    keyof ReturnType<typeof defineStore>
>

export default useNavigationStore

export type { NavigationStore }
