import { createLocalVue, shallowMount } from '@vue/test-utils'
import AppInputFile from '@/components/UI/AppInputFile.vue'
import basicMountWrapper from '~/utilitary/mountWrapper'

const localVue = createLocalVue()

describe('components/AppInputFile.vue', () => {
    let wrapper: any

    beforeEach(() => {
        wrapper = shallowMount(AppInputFile, {
            ...basicMountWrapper,
            localVue
        })
    })

    it('is a Vue instance', () => {
        expect(wrapper.vm).toBeTruthy()
        const component = wrapper.find('[data-testid="app_input_file"]')
        expect(component.exists()).toBeTruthy()
    })

    describe('label', () => {
        it('can display correct label when no filename', () => {
            expect(wrapper.vm.label).toBe('selectCSV')
        })

        it('can display correct label when filename', () => {
            wrapper.vm.filename = 'test.csv'
            expect(wrapper.vm.label).toBe('test.csv')
        })
    })

    describe('onFileChange', () => {
        it('can accept small csv files', () => {
            const toastSpyError = jest.spyOn(wrapper.vm.$toast?.global, 'error')
            const file = {
                name: 'test.csv',
                size: 200,
                type: 'text/csv'
            }
            wrapper.vm.onFileChange({ target: { files: [file] } })
            expect(wrapper.vm.filename).toBe('test.csv')
            expect(toastSpyError).not.toHaveBeenCalledWith()
        })

        it('can not accept heavy files', () => {
            const toastSpyError = jest.spyOn(wrapper.vm.$toast?.global, 'error')
            const file = {
                name: 'test.csv',
                size: 10000000
            }
            wrapper.vm.onFileChange({ target: { files: [file] } })
            expect(wrapper.vm.filename).toBe('')
            expect(toastSpyError).toHaveBeenCalledWith({
                title: wrapper.vm.$tc('errors.file_too_big'),
                message: ''
            })
        })

        it('can not accept non csv files', () => {
            const toastSpyError = jest.spyOn(wrapper.vm.$toast?.global, 'error')
            const file = {
                name: 'test.csv',
                size: 200,
                type: 'image/png'
            }
            wrapper.vm.onFileChange({ target: { files: [file] } })
            expect(wrapper.vm.filename).toBe('')
            expect(toastSpyError).toHaveBeenCalledWith({
                title: wrapper.vm.$tc('errors.file_must_be_csv'),
                message: ''
            })
        })
    })
})
