import { defineStore } from 'pinia'
import { ChorusProInformation } from '@/interfaces/chorusPro'

const useChorusProStore = defineStore('chorusPro', () => {
    const fetchChorusProInformation = (
        context: any,
        idBU: number | undefined
    ) =>
        context.$axios
            .$get(`/chorusproapi/business_units/${idBU}`)
            .then((res: any) => {
                const result = context.$toCamel(res)
                return result
            })
            .catch((e: any) => {
                throw e
            })

    const putChorusProInformation = (
        context: any,
        payload: {
            idBU: number | undefined
            chorusProInformation: ChorusProInformation
        }
    ) => {
        const { idBU, chorusProInformation } = payload
        const params = {
            siret: chorusProInformation.siret,
            service_code: chorusProInformation.serviceCode
                ? chorusProInformation.serviceCode
                : null,
            activated: chorusProInformation.activated
        }

        return context.$axios
            .$put(`/chorusproapi/business_units/${idBU}`, params)
            .then((res: any) => {
                const result = context.$toCamel(res)
                return result
            })
            .catch((e: any) => {
                throw e
            })
    }

    return {
        fetchChorusProInformation,
        putChorusProInformation
    }
})

type ChorusProStore = Omit<
    ReturnType<typeof useChorusProStore>,
    keyof ReturnType<typeof defineStore>
>

export default useChorusProStore

export type { ChorusProStore }
