import { createLocalVue, shallowMount } from '@vue/test-utils'
import AppDetailSectionLine from '@/components/UI/detailSection/AppDetailSectionLine.vue'
import basicMountWrapper from '~/utilitary/mountWrapper'

const localVue = createLocalVue()

const $emit = jest.fn()
describe('components/UI/AppDetailSectionLine.vue', () => {
    let wrapper: any

    beforeEach(() => {
        wrapper = shallowMount(AppDetailSectionLine, {
            ...basicMountWrapper,
            localVue,
            mocks: {
                ...basicMountWrapper.mocks,
                $emit
            },
            propsData: {
                fromTemplate: false
            }
        })
    })

    it('is a Vue instance', () => {
        expect(wrapper.vm).toBeTruthy()
        const component = wrapper.find(
            '[data-testid="app-detail-section-line"]'
        )
        expect(component.exists()).toBeTruthy()
    })
})
