import { RouterLinkStub } from '@vue/test-utils'
import { createTestingPinia } from '@pinia/testing'
import router from '~/test/mocks/router.mock'
import { i18n } from '~/test/mocks/i18n.mock'
import $toast from '~/test/mocks/toast.mock'
import {
    $toSnake,
    $toCamel,
    $toCapitalize,
    $toKebab
} from '~/test/mocks/json.mock'

const $auth = {
    strategy: {
        token: {
            get: jest.fn(() => 'token')
        }
    }
}
const $sentry = {
    captureException: jest.fn()
}
const $dialog = {
    confirm: jest.fn().mockResolvedValue(true)
}
const $route = {
    path: '/',
    hash: undefined,
    params: {
        idBU: 1,
        idOrga: 1
    },
    query: {
        idAuthorization: 1
    }
}

const basicMountWrapper: any = {
    pinia: createTestingPinia(),
    mocks: {
        $auth,
        $t: i18n.$t,
        $tc: i18n.$tc,
        $d: i18n.$d,
        $n: i18n.$n,
        $toSnake,
        $toCapitalize,
        $toKebab,
        $i18n: i18n.config,
        $axios: {
            post: jest.fn().mockResolvedValue({ data: 'success' })
        },
        $config: {
            FLEET_URL: 'https://fleetapi',
            CLOUD_URL: 'https://cloudapi',
            SUPERVISION_URL: 'supervision',
            EXPLOITATION_URL: 'https://test',
            SUPPORT: 'https://support.chargemap.com',
            BUSINESS_BLOG_URL: 'https://www.chargemap-business.com',
            WEBSOCKET_API: 'https://test',
            SUPPORT_EMAIL: 'email',
            SUPPORT_URL: 'https://test',
            REFUNDY_URL: 'https://refundyapi',
            EXPORTY_URL: 'https://exportyapi',
            NODE_ENV: 'development',
            NUXT_ENV: 'development'
        },
        $fetchState: {
            pending: false,
            error: ''
        },
        $toast,
        $sentry,
        $router: router,
        $route,
        $fetch: jest.fn(),
        $dialog,
        $toCamel,
        $bs: {
            $on: jest.fn(),
            $off: jest.fn(),
            $emit: jest.fn()
        },
        $emit: jest.fn()
    },
    stubs: {
        i18n: { template: '<div />' },
        NuxtLink: RouterLinkStub,
        NuxtChild: { template: '<div></div>' },
        StateFilter: {
            template: '<div></div>'
        },
        CollaboratorEmailInput: {
            template: '<input />'
        }
    }
}

export default basicMountWrapper
