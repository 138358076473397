import { createLocalVue, mount } from '@vue/test-utils'

import AppBottomSheet from '@/components/UI/AppBottomSheet.vue'
import basicMountWrapper from '~/utilitary/mountWrapper'
import useMobileFilter, { MobileFilterStore } from '~/store/mobileFilter'

const localVue = createLocalVue()

describe('components/UI/AppBottomSheet.vue', () => {
    let wrapper: any
    let mobileFilterStore: MobileFilterStore

    beforeEach(() => {
        mobileFilterStore = useMobileFilter()
        mobileFilterStore.filters = [
            {
                id: 2,
                value: 'filtre 2',
                name: 'Filtre 2',
                options: [
                    {
                        id: 1,
                        value: 'option 1',
                        name: 'Option 1'
                    }
                ]
            }
        ]
        mobileFilterStore.showBottomSheet = true
        mobileFilterStore.showSearchBar = false
        mobileFilterStore.hasSearch = false
        mobileFilterStore.toUpdate = false
        mobileFilterStore.addFilter = jest.fn().mockResolvedValue(true)
        mobileFilterStore.removeFilter = jest.fn().mockResolvedValue(true)

        wrapper = mount(AppBottomSheet, {
            ...basicMountWrapper,
            localVue
        })
    })

    it('is a Vue instance', () => {
        expect(wrapper.vm).toBeTruthy()
        const bsFilter = wrapper.find('.bs-filter')
        expect(bsFilter.exists()).toBeTruthy()
    })

    it('should addFilter', () => {
        wrapper.vm.addFilter({ id: 1, value: 'test' })
        expect(mobileFilterStore.addFilter).toHaveBeenCalledWith({
            id: 1,
            value: 'test'
        })
    })
    it('should removeFilter', () => {
        wrapper.vm.removeFilter(2)
        expect(mobileFilterStore.removeFilter).toHaveBeenCalledWith(2)
    })
    it('update', () => {
        wrapper.vm.update({ id: 1, value: 'test' })
        expect(mobileFilterStore.updateFilter).toHaveBeenCalledWith({
            id: 1,
            value: 'test'
        })
    })

    it('reset', () => {
        wrapper.vm.reset()
        expect(mobileFilterStore.resetFilter).toHaveBeenCalled()
        expect(mobileFilterStore.toUpdate).toBeTruthy()
    })

    it('close', () => {
        wrapper.vm.close()
        expect(mobileFilterStore.showBottomSheet).toBeFalsy()
        expect(mobileFilterStore.toUpdate).toBeTruthy()
    })
})
