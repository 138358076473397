import { ref } from 'vue'
import { defineStore } from 'pinia'
import useOrganizationStore from './organization'
import useSessionStore from './session'
import {
    BillingRecipient,
    BusinessUnit,
    BusinessUnitJSON,
    BillingAccount,
    BillingAccountJSON,
    PaymentMean,
    PaymentMeanJSON,
    OnboardingState,
    OnboardingStateJSON,
    OrderNumber,
    BillingRecipientJSON,
    BusinessUnitDTOJSON,
    OrderNumberJSON,
    InvoiceGrouping,
    InvoiceGroupingKey
} from '@/interfaces/businessunit'
import { BusinessFilter } from '@/interfaces/filters'
import { UserOrganization } from '~/interfaces/organization'
import usePaymentStore from '~/store/payment'
import useChargeStore from '~/store/charge'
import { BusinessUnitRight } from '~/interfaces/user'

const useBusinessUnitStore = defineStore('businessUnit', () => {
    const businessUnit = ref(null as BusinessUnit | null)
    const keyword = ref('' as string)
    const onboardingState = ref(null as OnboardingState | null)

    const hasOnboardingState = () => onboardingState.value !== null

    const hasFilled3OnboardingState = () =>
        onboardingState.value?.hasBillingAddress &&
        onboardingState.value?.hasShippingAddress &&
        onboardingState.value?.hasPaymentMean

    const hasBillingAddress = () => onboardingState.value?.hasBillingAddress

    const getRoles = () => businessUnit.value?.roles || null

    const getRolesFromOrganization = (
        organization: UserOrganization,
        index: number | null = null
    ) => {
        const roles = []

        if (organization) {
            if (index === 0 && organization?.businessUnits)
                return organization?.businessUnits[index]?.roles

            const businessUnits = organization?.businessUnits?.find(
                (bu: BusinessUnit) => bu.id === businessUnit.value?.id
            )

            if (businessUnits && businessUnits.roles) {
                roles.push(...businessUnits.roles)
            }
        }
        return roles
    }

    const setBusinessUnit = (bu: BusinessUnit | null) => {
        const { organization } = useOrganizationStore()
        if (bu && !bu.roles)
            bu.roles = getRolesFromOrganization(
                organization as UserOrganization
            )
        businessUnit.value = bu
        useChargeStore().resetCharge()
        useSessionStore().updateBusinessUnitRoles()
        return Promise.resolve()
    }

    const createBusinessUnit = async (
        context: any,
        payload: { idOrga: number; bu: BusinessUnitRight }
    ): Promise<BusinessUnitJSON> => {
        const { idOrga } = payload
        const bu: BusinessUnitJSON = context.$toSnake(payload.bu)
        delete bu.id
        if (bu.administrator.id === 0) {
            delete bu.administrator.id
        }
        const url = `/cloudapi/organizations/${idOrga}/business-units`
        const params = bu
        const res = await context.$axios.$post(url, params)
        payload.bu.id = res.id
        if (res.administrator?.id) {
            payload.bu.administrator.id = res.administrator.id
        }
        useSessionStore().pushBusinessUnit(idOrga, payload.bu)

        const { user } = useSessionStore()
        context.$auth.setUser(user)
        context.$auth.$storage.setUniversal('user', user)
        await context.$auth.refreshTokens()
        return res
    }

    const updateBusinessUnit = (
        context: any,
        params: { idOrga: number; bu: BusinessUnit }
    ): Promise<Boolean> =>
        new Promise((resolve, reject) => {
            const payload: BusinessUnitJSON = context.$toSnake({
                name: params.bu.name,
                notifyMainContact: params.bu.notifyMainContact
            })

            context.$axios
                .$patch(
                    `/cloudapi/organizations/${params.idOrga}/business-units/${params.bu.id}`,
                    payload
                )
                .then(async (bu: BusinessUnit) => {
                    await setBusinessUnit(context.$toCamel(bu))
                    useSessionStore().updateBusinessUnit(
                        Number(params.idOrga),
                        params.bu
                    )
                    const { user } = useSessionStore()
                    context.$auth.setUser(user)
                    context.$auth.$storage.setUniversal('user', user)
                    return context.$auth
                        .refreshTokens()
                        .then(() => resolve(true))
                        .catch((e: any) => {
                            reject(e)
                        })
                })
                .catch((e: any) => {
                    reject(e.response)
                })
        })

    const setBusinessUnitState = (
        context: any,
        payload: { idOrga: number; bu: BusinessUnit }
    ): Promise<BusinessUnitJSON> =>
        new Promise((resolve, reject) => {
            const { idOrga } = payload
            const bu = {
                activated: payload.bu.activated
            }
            context.$axios
                .$patch(
                    `/cloudapi/organizations/${idOrga}/business-units/${payload.bu.id}`,
                    bu
                )
                .then((res: BusinessUnitJSON) => {
                    try {
                        context.$auth.refreshTokens()
                    } catch (error) {
                        reject(error)
                    }
                    resolve(res)
                })
                .catch((e: any) => {
                    reject(e.response)
                })
        })

    const fetchBusinessUnit = (
        context: any,
        payload: { idOrga: number; idBU: number }
    ): Promise<BusinessUnit> => {
        const { idOrga, idBU } = payload
        return new Promise((resolve, reject) => {
            context.$axios
                .$get(
                    `/cloudapi/organizations/${idOrga}/business-units/${idBU}`
                )
                .then((res: BusinessUnitJSON) => resolve(context.$toCamel(res)))
                .catch((e: any) => reject(e))
        })
    }

    const getBillingAccount = (
        context: any,
        payload: { idOrga: number; idBU: number }
    ): Promise<BillingAccount> => {
        const paymentStore = usePaymentStore()
        return new Promise((resolve, reject) => {
            context.$axios
                .$get(
                    `/cloudapi/organizations/${payload.idOrga}/business-units/${payload.idBU}/billing-account`
                )
                .then((res: BillingAccountJSON) =>
                    resolve(context.$toCamel(res))
                )
                .catch((e: any) => {
                    if (e?.response?.status === 404) {
                        const billingAccount: BillingAccount = {
                            email: '',
                            lang: 'FR',
                            type: 'COMPANY',
                            paymentTerm: 0,
                            billingAddress: {
                                firstName: '',
                                name: '',
                                company: '',
                                addressLine1: '',
                                addressLine2: '',
                                postalCode: '',
                                city: '',
                                countryCode: '',
                                countryName: '',
                                phone: '',
                                vatNumber: ''
                            },
                            shippingSameAsBilling: true,
                            shippingAddress: {
                                firstName: '',
                                name: '',
                                company: '',
                                addressLine1: '',
                                addressLine2: '',
                                postalCode: '',
                                city: '',
                                countryCode: '',
                                countryName: '',
                                phone: '',
                                vatNumber: ''
                            },
                            additionalInformation: '',
                            additionalInformationValidity: undefined
                        }
                        paymentStore.billingAccount = billingAccount
                    }
                    reject(e)
                })
        })
    }

    const getPaymentMean = (
        context: any,
        payload: { idOrga: number; idBU: number }
    ): Promise<PaymentMean> =>
        new Promise((resolve, reject) => {
            context.$axios
                .$get(
                    `/cloudapi/organizations/${payload.idOrga}/business-units/${payload.idBU}/payment-mean`
                )
                .then((res: PaymentMeanJSON) => {
                    let payment: PaymentMean = context.$toCamel(res)
                    if (!payment) {
                        payment = {
                            id: null,
                            isDefault: false,
                            type: null
                        }
                    }
                    resolve(payment)
                })
                .catch((e: any) => reject(e))
        })

    const fetchOnboardingState = (
        context: any,
        route: { idOrga: number; idBU: number }
    ) => {
        const { idOrga, idBU } = route
        return context.$axios
            .get(
                `/cloudapi/organizations/${idOrga}/business-units/${idBU}/onboarding-state`
            )
            .then((res: { data: OnboardingStateJSON }) => {
                onboardingState.value = context.$toCamel(res.data)
                return onboardingState.value
            })
            .catch((e: any) => {
                throw e
            })
    }

    const fetchBillingRecipients = (
        context: any,
        payload: { idOrga: number; idBU: number }
    ): Promise<BillingRecipient[]> =>
        new Promise((resolve, reject) => {
            context.$axios
                .$get(
                    `/cloudapi/organizations/${payload.idOrga}/business-units/${payload.idBU}/billing-copies`
                )
                .then((res: { id: number; email: string }[]) => {
                    let billingRecipients: BillingRecipient[] = []

                    const billingRecipientResults: {
                        billingCopies: BillingRecipient[]
                    } = context.$toCamel(res)

                    if (billingRecipientResults.billingCopies) {
                        billingRecipients =
                            billingRecipientResults.billingCopies
                    }

                    resolve(billingRecipients)
                })
                .catch((e: any) => {
                    reject(e)
                })
        })

    const getBusinessUnit = async (
        context: any,
        payload: { idOrga: number; idBU: number }
    ): Promise<BusinessUnit | null> => {
        let error: any
        let tmpBusinessUnit = null as BusinessUnit | null
        const { idOrga, idBU } = payload
        await fetchBusinessUnit(context, { idOrga, idBU })
            .then((bu: BusinessUnit) => {
                if (bu === null) {
                    error = new Error('Business Unit not found')
                }
                tmpBusinessUnit = { ...bu }
            })
            .catch((e: any) => e)

        await Promise.all([
            getBillingAccount(context, payload)
                .then((billingAccount: BillingAccount) => {
                    if (tmpBusinessUnit && billingAccount) {
                        tmpBusinessUnit.billingAccount = billingAccount
                    }
                })
                .catch((e: any) => e),
            getPaymentMean(context, payload)
                .then((paymentMean: PaymentMean) => {
                    if (tmpBusinessUnit && paymentMean) {
                        tmpBusinessUnit.paymentMean = paymentMean
                    }
                })
                .catch((e: any) => e),
            fetchBillingRecipients(context, payload)
                .then((billingRecipients: BillingRecipient[]) => {
                    if (tmpBusinessUnit && billingRecipients) {
                        tmpBusinessUnit.billingRecipients = billingRecipients
                    }
                })
                .catch((e: any) => e)
        ])

        return new Promise((resolve, reject) => {
            if (error) reject(error)
            resolve(tmpBusinessUnit)
        })
    }

    const initBusinessUnit = (context: any, idBU: number | null = null) => {
        const organization = useOrganizationStore()
            .organization as UserOrganization
        if (organization) {
            return getBusinessUnit(context, {
                idOrga: organization.id,
                idBU: idBU || organization?.businessUnits[0].id
            }).then((res: any) => {
                if (!res) {
                    throw new Error('Business unit not found')
                }
                res.roles = getRolesFromOrganization(organization, 0)
                setBusinessUnit(res)
                return res
            })
        }

        return setBusinessUnit(
            !useSessionStore().isAdmin
                ? (organization as UserOrganization)?.businessUnits[0]
                : null
        )
    }

    const switchBusinessUnit = (
        context: any,
        params: { idBU: number; idOrga: number }
    ) => {
        const { organization } = useOrganizationStore()
        if (params.idOrga !== organization?.id) {
            return useOrganizationStore().switchOrganization(context, {
                organizationId: params.idOrga,
                idBU: params.idBU
            })
        }

        return getBusinessUnit(context, {
            idOrga: params.idOrga,
            idBU: params.idBU
        }).then((bu: BusinessUnit | null) => {
            setBusinessUnit(bu)
        })
    }

    const fetchBusinessUnitsAsAdmin = (
        context: any,
        filters: BusinessFilter
    ): Promise<{ count: number; businessUnits: BusinessUnit[] }> => {
        const offset =
            (filters.pages.index - 1) * filters.pages.perPage || (0 as number)
        const limit = filters.pages.perPage || (20 as number)
        const searchField = filters.searchField || (null as null | string)
        const params = {
            offset,
            limit,
            keyword: searchField
        }

        return new Promise((resolve, reject) => {
            context.$axios
                .get('/cloudapi/business-units', { params })
                .then(
                    (res: {
                        data: { items: BusinessUnitJSON[] }
                        headers: { 'x-total-count': string }
                    }) => {
                        const response = {
                            count: parseInt(res.headers['x-total-count'], 10),
                            businessUnits: context.$toCamel(res.data.items)
                        }
                        resolve(response)
                    }
                )
                .catch((e: any) => reject(e))
        })
    }

    const fetchBusinessUnits = (
        context: any,
        payload: { idOrga: number; filters: BusinessFilter }
    ): Promise<{ businessUnits: BusinessUnit[]; count: number }> => {
        const offset =
            (payload.filters.pages.index - 1) * payload.filters.pages.perPage ||
            (0 as number)
        const limit = payload.filters.pages.perPage || (20 as number)
        const isActivated = !payload.filters.hasAllBusinessUnit || null
        const searchField =
            payload.filters.searchField || (null as null | string)
        const params = {
            offset,
            limit,
            activated: isActivated,
            keyword: searchField
        }
        return new Promise((resolve, reject) => {
            context.$axios
                .$get(
                    `/cloudapi/organizations/${payload.idOrga}/business-units`,
                    { params }
                )
                .then((res: BusinessUnitDTOJSON) =>
                    resolve(context.$toCamel(res))
                )
                .catch((e: any) => reject(e))
        })
    }

    const updateBillingAccount = (
        context: any,
        payload: {
            idOrga: number
            idBU: number
            billingAccount: BillingAccount
        }
    ): Promise<BillingAccountJSON> =>
        new Promise((resolve, reject) => {
            if (!payload.billingAccount.additionalInformationValidity) {
                payload.billingAccount.additionalInformationValidity = undefined
            }
            const billingAccountJSON: BillingAccountJSON = context.$toSnake(
                payload.billingAccount
            )
            context.$axios
                .$put(
                    `/cloudapi/organizations/${payload.idOrga}/business-units/${payload.idBU}/billing-account`,
                    billingAccountJSON
                )
                .then((res: BillingAccountJSON) => resolve(res))
                .catch((e: any) => reject(e))
        })

    const patchOnboardingState = (
        context: any,
        payload: {
            idOrga: number
            idBU: number
            onBoardingState: {
                has_pass_orders?: boolean
                has_collaborators_or_vehicles?: boolean
                has_attached_passes?: boolean
            }
        }
    ) => {
        const { idOrga, idBU, onBoardingState } = payload

        return context.$axios
            .$patch(
                `/cloudapi/organizations/${idOrga}/business-units/${idBU}/onboarding-state`,
                onBoardingState
            )
            .catch((e: any) => {
                throw e
            })
    }

    const postBillingRecipient = (
        context: any,
        payload: { idOrga: number; idBU: number; email: string }
    ): Promise<BillingRecipientJSON> =>
        new Promise((resolve, reject) => {
            context.$axios
                .$post(
                    `/cloudapi/organizations/${payload.idOrga}/business-units/${payload.idBU}/billing-copies`,
                    { email: payload.email }
                )
                .then((res: BillingRecipientJSON) => resolve(res))
                .catch((e: any) => reject(e))
        })

    const updateBillingRecipient = (
        context: any,
        payload: {
            idOrga: number
            idBU: number
            billingRecipient: BillingRecipient
        }
    ): Promise<BillingRecipientJSON> =>
        new Promise((resolve, reject) => {
            context.$axios
                .$put(
                    `/cloudapi/organizations/${payload.idOrga}/business-units/${payload.idBU}/billing-copies/${payload.billingRecipient.id}`,
                    {
                        email: payload.billingRecipient.email,
                        group_invoices_notifications:
                            payload.billingRecipient.groupInvoicesNotifications
                    }
                )
                .then((res: BillingRecipientJSON) => resolve(res))
                .catch((e: any) => reject(e))
        })

    const deleteBillingRecipient = (
        context: any,
        payload: {
            idOrga: number
            idBU: number
            billingRecipient: { id: number; email: string }
        }
    ) =>
        new Promise((resolve, reject) => {
            context.$axios
                .$delete(
                    `/cloudapi/organizations/${payload.idOrga}/business-units/${payload.idBU}/billing-copies/${payload.billingRecipient.id}`
                )
                .then((res: any) => resolve(res))
                .catch((e: any) => reject(e))
        })

    const fetchOrderNumbers = (
        context: any,
        payload: { idOrga: number; idBU: number }
    ): Promise<OrderNumber[]> => {
        const { idBU, idOrga } = payload
        return new Promise((resolve, reject) => {
            context.$axios
                .$get(
                    `/cloudapi/organizations/${idOrga}/business-units/${idBU}/billing-account/additional-informations`
                )
                .then((res: { items: OrderNumberJSON[] }) =>
                    resolve(context.$toCamel(res.items))
                )
                .catch((e: any) => reject(e))
        })
    }

    const deleteOrderNumber = (
        context: any,
        payload: { idOrga: number; idBU: number; idOrderNumber: number }
    ) => {
        const { idBU, idOrga, idOrderNumber } = payload
        return new Promise((resolve, reject) => {
            context.$axios
                .$delete(
                    `/cloudapi/organizations/${idOrga}/business-units/${idBU}/billing-account/additional-informations/${idOrderNumber}`
                )
                .then((res: any) => {
                    resolve(res)
                })
                .catch((e: any) => {
                    reject(e)
                })
        })
    }

    const postOrderNumber = (
        context: any,
        payload: { idOrga: number; idBU: number; orderNumber: OrderNumber }
    ) => {
        const { orderNumber, idBU, idOrga } = payload
        return new Promise((resolve, reject) => {
            context.$axios
                .$post(
                    `/cloudapi/organizations/${idOrga}/business-units/${idBU}/billing-account/additional-informations`,
                    {
                        value: orderNumber.value,
                        start_date: orderNumber.startDate || null,
                        end_date: orderNumber.endDate || null
                    }
                )
                .then((res: any) => resolve(res))
                .catch((e: any) => reject(e))
        })
    }

    const updateOrderNumber = (
        context: any,
        payload: {
            idOrga: number
            idBU: number
            orderNumber: OrderNumber
        }
    ): Promise<OrderNumber> => {
        const { idBU, idOrga, orderNumber } = payload
        return new Promise((resolve, reject) => {
            context.$axios
                .$patch(
                    `/cloudapi/organizations/${idOrga}/business-units/${idBU}/billing-account/additional-informations/${orderNumber.id}`,
                    {
                        start_date: orderNumber.startDate || null,
                        end_date: orderNumber.endDate || null
                    }
                )
                .then((res: OrderNumber) => resolve(res))
                .catch((e: any) => reject(e))
        })
    }

    const fetchInvoiceGrouping = (
        context: any,
        idBU: number
    ): Promise<InvoiceGrouping> => {
        return new Promise((resolve, reject) => {
            context.$axios
                .$get(`/invoicyapi/business-units/${idBU}`)
                .then((res: InvoiceGrouping) => resolve(context.$toCamel(res)))
                .catch((e: any) => reject(e))
        })
    }

    const updateInvoiceGrouping = (
        context: any,
        payload: {
            idBU: number
            invoiceGroupingKey: InvoiceGroupingKey
        }
    ): Promise<InvoiceGrouping> => {
        return new Promise((resolve, reject) => {
            const { idBU, invoiceGroupingKey } = payload
            context.$axios
                .$put(`/invoicyapi/business-units/${idBU}`, {
                    invoice_grouping_key: invoiceGroupingKey
                })
                .then((res: InvoiceGrouping) => resolve(context.$toCamel(res)))
                .catch((e: any) => reject(e))
        })
    }

    return {
        businessUnit,
        keyword,
        onboardingState,
        hasOnboardingState,
        hasFilled3OnboardingState,
        hasBillingAddress,
        getRoles,
        getRolesFromOrganization,
        initBusinessUnit,
        switchBusinessUnit,
        setBusinessUnit,
        createBusinessUnit,
        updateBusinessUnit,
        setBusinessUnitState,
        fetchOnboardingState,
        getBusinessUnit,
        fetchBusinessUnit,
        fetchBusinessUnitsAsAdmin,
        fetchBusinessUnits,
        getBillingAccount,
        updateBillingAccount,
        getPaymentMean,
        patchOnboardingState,
        fetchBillingRecipients,
        postBillingRecipient,
        updateBillingRecipient,
        deleteBillingRecipient,
        fetchOrderNumbers,
        deleteOrderNumber,
        postOrderNumber,
        updateOrderNumber,
        fetchInvoiceGrouping,
        updateInvoiceGrouping
    }
})

type BusinessUnitStore = Omit<
    ReturnType<typeof useBusinessUnitStore>,
    keyof ReturnType<typeof defineStore>
>

export default useBusinessUnitStore

export type { BusinessUnitStore }
