import { defineStore } from 'pinia'
import { AxiosError } from 'axios'
import {
    Pass,
    Passes,
    PassJSON,
    PassLicense,
    PassLicenseJSON,
    PassOwner,
    PassOwnerJSON,
    PassRulesHolidays,
    PassRulesHolidaysJSON,
    RuleHolidays,
    RulesHolidays,
    RulesHolidaysJSON,
    RuleWeekly,
    RuleWeeklyJSON,
    TeslaAssociationPassJson,
    TransferType
} from '@/interfaces/pass'
import { FilterPass, FilterTeslaPass } from '@/interfaces/filters'
import validHexa from '@/validations/hexa'
import { axiosListWrapper } from '~/utilitary/storeWrapperVuex'
import { Address, DTOAddress, OrderJSON } from '~/interfaces/order'
import { PassesJSON } from '~/interfaces/admin'

const usePassStore = defineStore('pass', () => {
    const fetchPasses = (
        context: any,
        payload: {
            idOrga: number
            idBU: number
            filters: FilterPass
            collaboratorAssigned?: number
            vehicleAssigned?: number
        }
    ): Promise<Passes> => {
        const { idOrga, idBU, filters, collaboratorAssigned, vehicleAssigned } =
            payload
        const {
            pages,
            hasLimit,
            isAssigned,
            searchField,
            lost,
            type,
            isActivated
        } = filters
        const offset = (pages.index - 1) * pages.perPage || (0 as number)
        const limit = pages.perPage || 20
        const keyword = searchField || (null as null | string)

        const params = {
            offset,
            limit,
            keyword,
            lost,
            is_activated: isActivated,
            has_limit: hasLimit,
            is_assigned: isAssigned,
            type,
            collaborator_assigned: collaboratorAssigned,
            vehicle_assigned: vehicleAssigned
        }

        if (!type) {
            delete params.type
        }

        const url = `/cloudapi/organizations/${idOrga}/business-units/${idBU}/passes`
        return context.$axios
            .$get(url, { params })
            .then((res: PassesJSON) => {
                const result: {
                    count: number
                    next: null
                    previous: null
                    passes: Pass[]
                } = context.$toCamel(res)
                result.passes.forEach((pass: Pass) => {
                    pass.limit = pass.limit ? pass.limit / 100 : null
                })
                return result
            })
            .catch((e: AxiosError) => Promise.reject(e))
    }

    const fetchSupervisedPasses = (
        context: any,
        payload: {
            idOrga: number
            idBU: number
            filters: FilterPass
        }
    ): Promise<Passes> => {
        const { idOrga, idBU, filters } = payload
        const { pages, hasLimit, isAssigned, searchField, lost, type } = filters
        const offset = (pages.index - 1) * pages.perPage || (0 as number)
        const limit = pages.perPage || 20
        const keyword = searchField || (null as null | string)

        const params = {
            offset,
            limit,
            keyword,
            lost,
            has_limit: hasLimit,
            is_assigned: isAssigned,
            type
        }

        if (!type) {
            delete params.type
        }

        const url = `/cloudapi/organizations/${idOrga}/business-units/${idBU}/passes/supervised`
        return context.$axios
            .$get(url, { params })
            .then((res: PassesJSON) => {
                const result: {
                    count: number
                    next: null
                    previous: null
                    passes: Pass[]
                } = context.$toCamel(res)
                result.passes.forEach((pass: Pass) => {
                    pass.limit = pass.limit ? pass.limit / 100 : null
                })
                return result
            })
            .catch((e: AxiosError) => Promise.reject(e))
    }

    const fetchTeslaPasses = (
        context: any,
        payload: { idBU: number; filters: FilterTeslaPass }
    ) => {
        const { idBU, filters } = payload
        const { teslaLinkStatus } = filters
        const params = {
            tesla_link_status: teslaLinkStatus
        }
        const url = `/fleetapi/business-units/${idBU}/tesla_associations`
        return axiosListWrapper
            .bind(context)(url, params, filters)
            .then(
                (res: {
                    totalCount: number
                    items: TeslaAssociationPassJson[]
                    previous: string | null
                    next: string | null
                }) => ({
                    totalCount: res.totalCount,
                    passes: res.items,
                    previous: res.previous,
                    next: res.next
                })
            )
    }

    const getPass = (
        context: any,
        payload: { idOrga: number; idBU: number; idPass: number }
    ) => {
        const { idOrga, idBU, idPass } = payload

        return context.$axios
            .$get(
                `/cloudapi/organizations/${idOrga}/business-units/${idBU}/passes/${idPass}`
            )
            .then((res: PassJSON[]) => {
                const pass: Pass = context.$toCamel(res)
                pass.limit = pass.limit ? pass.limit / 100 : null
                return pass
            })
            .catch((e: AxiosError) => Promise.reject(e))
    }

    const fetchLicense = (
        context: any,
        payload: { idBU: number; idPass: number }
    ): Promise<PassLicense> => {
        const { idBU, idPass } = payload

        const url = `/fleetapi/business-units/${idBU}/passes/${idPass}`
        return context.$axios
            .$get(url)
            .then((res: PassLicenseJSON) => context.$toCamel(res))
            .catch((e: AxiosError) => Promise.reject(e))
    }

    const newPrivatePass = (
        context: any,
        payload: {
            idAuthorization: string | undefined
            pass: Pass
            idBU: number
        }
    ) => {
        const { pass, idBU, idAuthorization } = payload
        const newPass = {
            authorization_id: undefined as number | undefined,
            rfid_uid: undefined as string | undefined,
            number: pass.number,
            comment: pass.comment
        }
        if (idAuthorization) {
            newPass.authorization_id = Number(idAuthorization)
        } else {
            newPass.rfid_uid = pass.rfidUid || undefined
        }

        if (!newPass.rfid_uid && !newPass.authorization_id) {
            return new Error('pass.form.missing.uid')
        }
        if (!newPass.number) {
            return new Error('pass.form.missing.number')
        }
        if (
            !newPass.authorization_id &&
            newPass.rfid_uid &&
            !validHexa(newPass.rfid_uid)
        ) {
            return new Error('pass.form.invalid.uid')
        }
        return context.$axios
            .$post(`/supervisionapi/business-units/${idBU}/passes`, newPass)
            .then((res: PassJSON) => res)
            .catch((e: AxiosError) => Promise.reject(e))
    }

    const newClassicPass = (
        context: any,
        payload: { pass: Pass; idOrga: number; idBU: number }
    ) => {
        const { idBU, pass, idOrga } = payload
        return context.$axios
            .$post(
                `/cloudapi/organizations/${idOrga}/business-units/${idBU}/passes`,
                {
                    number: pass.number,
                    type: pass.type
                }
            )
            .then((res: PassJSON) => context.$toCamel(res))
            .catch((e: AxiosError) => Promise.reject(e))
    }

    const verifyPassCommunity = (
        context: any,
        payload: { passNumber: string; idOrga: number; idBU: number }
    ) => {
        const { passNumber } = payload
        return context.$axios
            .$get(`/cloudapi/passes/${passNumber}`)
            .then((res: any) => context.$toCamel(res))
            .catch((e: AxiosError) => Promise.reject(e))
    }

    const updateInfoPass = (
        context: any,
        payload: { pass: Pass; idOrga: number; idBU: number }
    ) => {
        const { pass, idOrga, idBU } = payload
        const updatePass = {
            number: pass.number,
            limit: pass.limit ? pass.limit * 100 : null,
            comment: pass.comment,
            vehicle_id: pass.vehicle?.id,
            collaborator_id: pass.collaborator?.id,
            cost_center_id: pass.costCenter?.id
        }

        return context.$axios
            .$put(
                `/cloudapi/organizations/${idOrga}/business-units/${idBU}/passes/${pass.id}`,
                updatePass
            )
            .then((res: PassJSON) => {
                const passUpdated = context.$toCamel(res)
                passUpdated.limit = passUpdated.limit
                    ? passUpdated.limit / 100
                    : null
                return passUpdated
            })
            .catch((e: AxiosError) => Promise.reject(e))
    }

    const orderPass = (
        context: any,
        payload: {
            passNumber: number
            licenseNumber: number
            idBU: number
            shippingAddress: Address | undefined
            collaborators: number[]
            vehicles: number[]
            isLicenseMode: boolean
        }
    ) => {
        const {
            passNumber,
            licenseNumber,
            idBU,
            shippingAddress,
            collaborators,
            vehicles,
            isLicenseMode
        } = payload

        let dtoAddress: DTOAddress | null = null
        if (!isLicenseMode) {
            dtoAddress = {
                firstName: shippingAddress?.firstName || '',
                lastName: shippingAddress?.name || '',
                recipient: shippingAddress?.company || '',
                addressLine1: shippingAddress?.addressLine1 || '',
                addressLine2: shippingAddress?.addressLine2 || '',
                postalCode: shippingAddress?.postalCode || '',
                city: shippingAddress?.city || '',
                country: shippingAddress?.countryName || '',
                countryCode: shippingAddress?.countryCode || ''
            }
        }

        const params = {
            passNumber: passNumber || undefined,
            licenseNumber: licenseNumber || undefined,
            shippingAddress: dtoAddress,
            collaboratorsIds: collaborators || [],
            vehiclesIds: vehicles || []
        }

        return context.$axios
            .$post(
                `/fleetapi/business-units/${idBU}/orders`,
                context.$toSnake(params)
            )
            .then((res: OrderJSON) => res)
            .catch((e: AxiosError) => Promise.reject(e))
    }

    const fetchOwnerPass = (
        context: any,
        serialNumber: string
    ): Promise<PassOwner> =>
        new Promise((resolve, reject) =>
            context.$axios
                .$get(`/fleetapi/passes?serial=${serialNumber}`)
                .then((res: PassOwnerJSON) => resolve(context.$toCamel(res)))
                .catch((e: AxiosError) => {
                    reject(e)
                })
        )

    const transferPass = (
        context: any,
        payload: {
            idOrga: number
            idBU: number
            idPass: number
            typeTransfer: TransferType
        }
    ) => {
        const { idBU, idPass, typeTransfer } = payload
        const params = {
            business_unit_id: idBU
        }

        let url = `/fleetapi/passes/${idPass}/transfer/`
        url +=
            typeTransfer === 'external'
                ? 'from_community_pass'
                : 'from_business_unit_pass'
        return context.$axios
            .$patch(url, params)
            .then((res: any) => context.$toCamel(res))
            .catch((e: AxiosError) => Promise.reject(e))
    }

    const actionsOnPass = (
        context: any,
        payload: { idBU: number; idPass: number; params: object }
    ): Promise<PassLicense> => {
        const { idBU, idPass, params } = payload
        return context.$axios
            .$patch(`/fleetapi/business-units/${idBU}/passes/${idPass}`, params)
            .then((res: PassLicenseJSON) => context.$toCamel(res))
            .catch((e: AxiosError) => Promise.reject(e))
    }

    const activate = (
        context: any,
        payload: { idBU: number; idPass: number }
    ) => {
        const { idBU, idPass } = payload
        const params = {
            activated: true
        }

        return actionsOnPass(context, {
            idBU,
            idPass,
            params
        })
    }

    const deactivate = (
        context: any,
        payload: { idBU: number; idPass: number }
    ) => {
        const { idBU, idPass } = payload
        const params = {
            activated: false
        }

        return actionsOnPass(context, {
            idBU,
            idPass,
            params
        })
    }

    const deletePass = (
        context: any,
        payload: { idBU: number; idOrga: number; idPass: number }
    ) => {
        const { idBU, idPass, idOrga } = payload
        return context.$axios
            .$delete(
                `/cloudapi/organizations/${idOrga}/business-units/${idBU}/passes/${idPass}`
            )
            .then((res: any) => context.$toCamel(res))
    }

    const cancelLicense = (
        context: any,
        payload: { idBU: number; idPass: number }
    ) => {
        const { idBU, idPass } = payload
        const params = {
            renewable: false
        }

        return actionsOnPass(context, {
            idBU,
            idPass,
            params
        })
    }

    const renewLicense = (
        context: any,
        payload: { idBU: number; idPass: number }
    ) => {
        const { idBU, idPass } = payload
        const params = {
            renewable: true
        }
        return actionsOnPass(context, {
            idBU,
            idPass,
            params
        })
    }

    const isLost = (
        context: any,
        payload: { idBU: number; idPass: number }
    ): Promise<{ dateLost: string }> => {
        const { idBU, idPass } = payload
        const params = {
            lost: true
        }
        return context.$axios
            .$post(
                `/fleetapi/business-units/${idBU}/passes/${idPass}/report_lost`,
                params
            )
            .then((res: { date_lost: string }) => context.$toCamel(res))
            .catch((e: AxiosError) => Promise.reject(e))
    }

    const isFound = (
        context: any,
        payload: { idBU: number; idPass: number }
    ): Promise<{ dateLost: null }> => {
        const { idBU, idPass } = payload
        const params = {
            lost: false
        }
        return context.$axios
            .$post(
                `/fleetapi/business-units/${idBU}/passes/${idPass}/report_lost`,
                params
            )
            .then((res: { date_lost: null }) => context.$toCamel(res))
            .catch((e: AxiosError) => Promise.reject(e))
    }

    const approveTeslaAssociation = (
        context: any,
        payload: { idBU: number; uuid: string }
    ) => {
        const { uuid, idBU } = payload
        const params = {
            tesla_link_status: 'CONFIRMED'
        }
        return context.$axios
            .$patch(
                `fleetapi/business-units/${idBU}/tesla_associations/${uuid}`,
                params
            )
            .then((res: any) => context.$toCamel(res))
            .catch((e: AxiosError) => Promise.reject(e))
    }

    const rejectTeslaAssociation = (
        context: any,
        payload: { idBU: number; uuid: string }
    ) => {
        const { uuid, idBU } = payload
        return context.$axios
            .$delete(
                `fleetapi/business-units/${idBU}/tesla_associations/${uuid}`
            )
            .then((res: any) => context.$toCamel(res))
            .catch((e: AxiosError) => Promise.reject(e))
    }

    const unlinkTeslaAssociation = (
        context: any,
        payload: { idBU: number; uuid: string }
    ) => {
        const { uuid, idBU } = payload
        return context.$axios
            .$delete(
                `fleetapi/business-units/${idBU}/tesla_associations/${uuid}`
            )
            .then((res: any) => context.$toCamel(res))
            .catch((e: AxiosError) => Promise.reject(e))
    }

    const fetchRuleWeekly = (
        context: any,
        payload: { idBU: number; idPass: number | null }
    ): Promise<RuleWeekly> => {
        const { idBU, idPass } = payload
        let url = `blockyapi/business-units/${idBU}/rule/weekly`
        if (idPass) {
            url = `blockyapi/business-units/${idBU}/passes/${idPass}/rule/weekly`
        }

        return context.$axios
            .$get(url)
            .then((res: RuleWeeklyJSON) => context.$toCamel(res))
            .catch((e: AxiosError) => Promise.reject(e))
    }

    const saveRuleWeekly = (
        context: any,
        payload: {
            idBU: number
            idPass: number | null
            ruleWeekly: RuleWeekly
            overrideCustomRules: boolean
        }
    ): Promise<RuleWeekly> => {
        const { idBU, idPass, ruleWeekly, overrideCustomRules } = payload
        let url = `blockyapi/business-units/${idBU}/rule/weekly`
        if (idPass) {
            url = `blockyapi/business-units/${idBU}/passes/${idPass}/rule/weekly`
        }
        const params = {
            business_unit_rule: ruleWeekly.businessUnitRule,
            override_custom_rules: overrideCustomRules,
            days: {
                SUNDAY: context.$toSnake(ruleWeekly.days.SUNDAY),
                MONDAY: context.$toSnake(ruleWeekly.days.MONDAY),
                TUESDAY: context.$toSnake(ruleWeekly.days.TUESDAY),
                WEDNESDAY: context.$toSnake(ruleWeekly.days.WEDNESDAY),
                THURSDAY: context.$toSnake(ruleWeekly.days.THURSDAY),
                FRIDAY: context.$toSnake(ruleWeekly.days.FRIDAY),
                SATURDAY: context.$toSnake(ruleWeekly.days.SATURDAY)
            }
        }
        return context.$axios
            .$put(url, params)
            .then((res: RuleWeeklyJSON) => context.$toCamel(res))
            .catch((e: AxiosError) => Promise.reject(e))
    }

    const deleteRuleWeekly = (
        context: any,
        payload: { idBU: number; idPass: number }
    ) => {
        const { idBU, idPass } = payload

        return context.$axios
            .$delete(
                `blockyapi/business-units/${idBU}/passes/${idPass}/rule/weekly`
            )
            .then((res: any) => context.$toCamel(res))
            .catch((e: AxiosError) => Promise.reject(e))
    }

    const fetchRuleHolidays = (
        context: any,
        payload: { idBU: number; idPass: number | null }
    ): Promise<PassRulesHolidays | RulesHolidays> => {
        const { idBU, idPass } = payload
        let url = `blockyapi/business-units/${idBU}/rule/holidays`
        if (idPass) {
            url = `blockyapi/business-units/${idBU}/passes/${idPass}/rule/holidays`
        }

        return context.$axios
            .$get(url)
            .then((res: PassRulesHolidaysJSON | RulesHolidaysJSON) =>
                context.$toCamel(res)
            )
            .catch((e: AxiosError) => Promise.reject(e))
    }

    const saveRuleHolidays = (
        context: any,
        payload: {
            idBU: number
            idPass: number | null
            ruleHolidays: RuleHolidays | null
            onlyBU: boolean
            activatePasses: boolean
        }
    ): Promise<PassRulesHolidays | RulesHolidays> => {
        const { idBU, idPass, ruleHolidays, activatePasses, onlyBU } = payload
        let url = `blockyapi/business-units/${idBU}/rule/holidays`
        if (idPass) {
            url = `blockyapi/business-units/${idBU}/passes/${idPass}/rule/holidays`
        }
        let callType = '$post'
        if (ruleHolidays?.id) {
            url += `/${ruleHolidays.id}`
            callType = onlyBU ? '$patch' : '$put'
        }
        const params = {
            ...context.$toSnake(ruleHolidays),
            activate_passes: activatePasses
        }
        return context.$axios[callType](url, params)
            .then((res: PassRulesHolidaysJSON | RulesHolidaysJSON) =>
                context.$toCamel(res)
            )
            .catch((e: AxiosError) => Promise.reject(e))
    }

    const deleteRuleHolidays = (
        context: any,
        payload: { idBU: number; idPass: number; idRule: number }
    ) => {
        const { idBU, idPass, idRule } = payload
        let url = `blockyapi/business-units/${idBU}/rule/holidays/${idRule}`
        if (idPass) {
            url = `blockyapi/business-units/${idBU}/passes/${idPass}/rule/holidays/${idRule}`
        }

        return context.$axios
            .$delete(url)
            .then((res: any) => context.$toCamel(res))
            .catch((e: AxiosError) => Promise.reject(e))
    }

    return {
        fetchPasses,
        fetchSupervisedPasses,
        fetchTeslaPasses,
        getPass,
        fetchLicense,
        newPrivatePass,
        newClassicPass,
        verifyPassCommunity,
        updateInfoPass,
        orderPass,
        fetchOwnerPass,
        transferPass,
        actionsOnPass,
        activate,
        deactivate,
        deletePass,
        cancelLicense,
        renewLicense,
        isLost,
        isFound,
        approveTeslaAssociation,
        rejectTeslaAssociation,
        unlinkTeslaAssociation,
        fetchRuleWeekly,
        saveRuleWeekly,
        deleteRuleWeekly,
        fetchRuleHolidays,
        saveRuleHolidays,
        deleteRuleHolidays
    }
})

type PassStore = Omit<
    ReturnType<typeof usePassStore>,
    keyof ReturnType<typeof defineStore>
>

export default usePassStore

export type { PassStore }
