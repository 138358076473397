
import Vue from 'vue'

export default Vue.extend({
    // https://tailwindui.com/components/application-ui/elements/badges
    name: 'AppBadge',
    props: {
        color: {
            type: String,
            default: 'blue',
            validator(value: string) {
                return [
                    'gray',
                    'red',
                    'yellow',
                    'orange',
                    'green',
                    'blue',
                    'indigo',
                    'purple',
                    'pink',
                    'chargemap'
                ].includes(value)
            }
        },
        size: {
            type: String,
            default: 'xs',
            validator(value: string) {
                return ['xxs', 'xs', 'sm', 'md'].includes(value)
            }
        },
        isDisabled: {
            type: Boolean,
            default: false
        },
        dark: {
            type: Boolean,
            default: false
        },
        indicator: {
            type: Boolean,
            default: false
        },
        squared: {
            type: Boolean,
            default: false
        },
        bordered: {
            type: Boolean,
            default: false
        },
        vertical: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        sizeComputed(): string {
            if (this.size === 'sm') {
                return this.squared ? 'px-2.5 py-1' : 'px-3 py-1'
            }
            if (this.size === 'xxs') {
                return this.squared ? 'px-0.5' : 'px-1'
            }
            return this.squared ? 'px-2' : 'px-2.5'
        }
    }
})
