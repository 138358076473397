import { createLocalVue, shallowMount } from '@vue/test-utils'
import AppCheckbox from '@/components/UI/AppCheckbox.vue'

// Mocks
import { i18n } from '~/test/mocks/i18n.mock'
import useMobileFilter, { MobileFilterStore } from '~/store/mobileFilter'
import basicMountWrapper from '~/utilitary/mountWrapper'

const localVue = createLocalVue()

const mocks = {
    $t: i18n.$t,
    $tc: i18n.$tc,
    $emit: jest.fn()
}

describe('components/UI/AppCheckbox.vue', () => {
    let wrapper: any
    let mobileFilterStore: MobileFilterStore

    beforeAll(() => {
        wrapper = shallowMount(AppCheckbox, {
            ...basicMountWrapper,
            localVue,
            mocks: {
                ...basicMountWrapper.mocks,
                ...mocks
            }
        })

        mobileFilterStore = useMobileFilter()
        mobileFilterStore.filters = []
        mobileFilterStore.showBottomSheet = false
        mobileFilterStore.showSearchBar = false
        mobileFilterStore.hasSearch = false
        mobileFilterStore.toUpdate = false
    })

    afterEach(() => {
        jest.clearAllMocks()
    })

    it('is a Vue instance', () => {
        expect(wrapper.vm).toBeTruthy()
        const component = wrapper.find('[data-testid="app-checkbox"]')
        expect(component.exists()).toBeTruthy()
    })

    it('should emit value on update', async () => {
        const spyStoreCommit = jest.spyOn(mobileFilterStore, 'updateFilter')
        expect(mocks.$emit).not.toHaveBeenCalled()
        await wrapper.vm.update(true)
        expect(mocks.$emit).toHaveBeenCalledWith('input', true)
        expect(mocks.$emit).toHaveBeenCalledWith('change', true)
        expect(spyStoreCommit).toHaveBeenCalled()
    })

    it('should call update on same id', () => {
        const newValue = 'test'
        const updateSpy = jest.spyOn(wrapper.vm, 'update')
        expect(updateSpy).not.toHaveBeenCalled()
        wrapper.vm.updateLocalFilter({
            id: 'unknown',
            value: newValue
        })
        expect(updateSpy).not.toHaveBeenCalled()
        wrapper.vm.updateLocalFilter({
            id: wrapper.vm._uid,
            value: newValue
        })
        expect(updateSpy).toHaveBeenCalledWith(newValue)
    })

    it('should call update with checked', () => {
        const evt = {
            target: {
                checked: true
            }
        }
        const updateSpy = jest.spyOn(wrapper.vm, 'update')
        expect(updateSpy).not.toHaveBeenCalled()
        wrapper.vm.changeValue(evt)
        expect(updateSpy).toHaveBeenCalledWith(evt.target.checked)
    })

    it('can return label', async () => {
        expect(wrapper.vm.label).toBe('enable')

        await wrapper.setProps({
            value: true
        })

        expect(wrapper.vm.label).toBe('disable')

        await wrapper.setProps({
            value: false
        })

        expect(wrapper.vm.label).toBe('enable')
    })
})
