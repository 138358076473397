var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('span',{staticClass:"app-badge",class:[
        _vm.isDisabled ? 'bg-gray-100 text-gray-400 border-color-300' : '',
        _vm.dark ? 'bg-' + _vm.color + '-600' : 'bg-' + _vm.color + '-200',
        _vm.dark ? 'text-white' : 'text-' + _vm.color + '-900',
        'text-' + _vm.size,
        _vm.sizeComputed,
        _vm.squared
            ? _vm.size === 'sm'
                ? 'rounded-md'
                : 'rounded'
            : 'rounded-full',
        _vm.bordered ? 'border border-' + _vm.color + '-400' : ''
    ],attrs:{"data-testid":"app-badge"}},[(_vm.indicator)?_c('svg',{staticClass:"-ml-0.5 mr-1.5 h-2 w-2",class:[
            'text-' + _vm.color + '-400',
            (_vm.squared && _vm.size === 'sm') || (!_vm.squared && _vm.size === 'xs')
                ? '-ml-0.5'
                : _vm.squared
                  ? ''
                  : '-ml-1'
        ],attrs:{"data-testid":"app-badge-indicator","fill":"currentColor","viewBox":"0 0 8 8"}},[_c('circle',{attrs:{"cx":"4","cy":"4","r":"3"}})]):_vm._e(),_vm._v(" "),(_vm.$slots.icon)?_c('span',{staticClass:"mr-1"},[_vm._t("icon")],2):_vm._e(),_vm._v(" "),_vm._t("default"),_vm._v(" "),(_vm.$slots.iconRight)?_c('span',{staticClass:"ml-1"},[_vm._t("iconRight")],2):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }