import { createLocalVue, shallowMount } from '@vue/test-utils'
import ChargemapModal from '@/components/UI/CMUI/ChargemapModal.vue'
import basicMountWrapper from '~/utilitary/mountWrapper'

const props = {
    showX: true
}

const localVue = createLocalVue()

describe('ChargemapModal', () => {
    let wrapper: any
    beforeEach(() => {
        wrapper = shallowMount(ChargemapModal, {
            ...basicMountWrapper,
            localVue,
            propsData: props
        })
    })

    it('is a Vue instance', () => {
        expect(wrapper.vm).toBeTruthy()
        const component = wrapper.find('[data-testid="chargemap-modal"]')
        expect(component.exists()).toBeTruthy()
    })

    describe('methods', () => {
        it('can closeModal', async () => {
            const spyCloseModal = jest.spyOn(wrapper.vm, 'closeModal')

            await wrapper.vm.closeModal()

            expect(spyCloseModal).toBeCalled()
            expect(wrapper.emitted().updateIsVisible[0]).toEqual([false])
        })
        it('can closeModalWhenEscape', async () => {
            const spyCloseModalWhenEscape = jest.spyOn(
                wrapper.vm,
                'closeModalWhenEscape'
            )

            // Simulated Key event
            await wrapper.vm.closeModalWhenEscape({ key: 'quelqueChose' })

            expect(spyCloseModalWhenEscape).toBeCalledWith({
                key: 'quelqueChose'
            })
            expect(wrapper.emitted().updateIsVisible).toBeFalsy()

            // Simulated Key event
            await wrapper.vm.closeModalWhenEscape({ key: 'Escape' })

            expect(spyCloseModalWhenEscape).toBeCalledWith({
                key: 'Escape'
            })

            expect(wrapper.emitted().updateIsVisible[0]).toEqual([false])
        })

        it('can clickedOutside', async () => {
            const spyClickedOutside = jest.spyOn(wrapper.vm, 'clickedOutside')

            await wrapper.vm.clickedOutside()

            expect(spyClickedOutside).toBeCalled()
            expect(wrapper.emitted().clickedOutside).toHaveLength(1)
        })
    })
})
