
import Vue from 'vue'
import { mapActions, mapState } from 'pinia'
import useMobileFilterStore from '~/store/mobileFilter'
import IconArrowDown from '~/components/UI/icons/dropdown/IconArrowDown.vue'
import IconCheck from '~/components/UI/icons/dropdown/IconCheck.vue'
import IconCheckCircle from '~/components/UI/icons/IconCheckCircle.vue'

import { Filters } from '~/interfaces/filters'

export default Vue.extend({
    name: 'AppBasicFilter',
    components: {
        IconArrowDown,
        IconCheck,
        IconCheckCircle
    },
    props: {
        value: {
            type: Boolean,
            default: undefined
        },
        prefixLoco: {
            type: String,
            required: true
        }
    },
    computed: {
        ...mapState(useMobileFilterStore, ['filters']),
        label(): string {
            return this.value === null
                ? this.$t(`${this.prefixLoco}title`).toString()
                : this.getLabel(this.value)
        },
        BSfilter(this: any): Filters {
            return {
                id: this._uid,
                name: this.$tc(`${this.prefixLoco}title`),
                value: this.value,
                options: [
                    {
                        id: 1,
                        name: '',
                        value: null
                    },
                    {
                        id: 2,
                        name: this.$tc(`${this.prefixLoco}yes`),
                        value: true
                    },
                    {
                        id: 3,
                        name: this.$tc(`${this.prefixLoco}no`),
                        value: false
                    }
                ]
            } as Filters
        }
    },
    watch: {
        filters: {
            handler(this: any, val) {
                const filter = val.find((f: any) => f.id === this._uid)
                if (useMobileFilterStore().showBottomSheet) {
                    this.updateLocalFilter(filter)
                }
            },
            deep: true
        }
    },
    mounted(this: any) {
        this.addFilter(this.BSfilter)
    },
    beforeDestroy(this: any) {
        this.removeFilter(this._uid)
    },
    methods: {
        ...mapActions(useMobileFilterStore, [
            'addFilter',
            'removeFilter',
            'updateFilter'
        ]),
        toggleDropdown(slotProps: any, shouldUpdateRef: boolean = false) {
            if (slotProps) {
                slotProps.toggleShowList()
                if (shouldUpdateRef) {
                    slotProps.setButtonRef(this.$refs.buttonRef)
                }
            }
        },
        getLabel(value: boolean | null) {
            let label = this.prefixLoco
            if (value) {
                label += 'yes'
            } else if (value === false) {
                label += 'no'
            } else {
                label += 'all'
            }
            return this.$tc(label)
        },
        update(this: any, value: boolean | null, slotProps: any) {
            this.$emit('input', value)
            this.updateFilter({
                id: this._uid,
                value
            })
            this.toggleDropdown(slotProps)
        },
        updateLocalFilter(this: any, filter: any) {
            if (filter.id === this._uid) {
                this.update(filter.value)
            }
        }
    }
})
