
import { mapState } from 'pinia'
import useMobileFilterStore from '~/store/mobileFilter'
import IconClose from '~/components/UI/icons/notification/IconClose.vue'
import { Filters } from '~/interfaces/filters'

export default {
    name: 'AppBottomSheet',
    components: { IconClose },
    computed: {
        ...mapState(useMobileFilterStore, ['filters', 'showBottomSheet'])
    },
    beforeDestroy() {
        useMobileFilterStore().resetFilters()
    },
    methods: {
        addFilter(newFilter: Filters) {
            useMobileFilterStore().addFilter(newFilter)
        },
        removeFilter(filterId: number) {
            useMobileFilterStore().removeFilter(filterId)
        },
        update(filter: Filters) {
            useMobileFilterStore().updateFilter(filter)
        },
        reset() {
            useMobileFilterStore().resetFilter()
            useMobileFilterStore().toUpdate = true
        },
        close() {
            useMobileFilterStore().toUpdate = true
            useMobileFilterStore().showBottomSheet =
                !useMobileFilterStore().showBottomSheet
        }
    }
}
