import { createLocalVue, shallowMount } from '@vue/test-utils'
import AppSelectCountry from '@/components/UI/AppSelectCountry.vue'

// Mocks
import { i18n } from '~/test/mocks/i18n.mock'

const listCountriesFR = {
    AD: 'Andorre',
    AE: 'Émirats Arabes Unis',
    AF: 'Afghanistan',
    AG: 'Antigua-et-Barbuda',
    AI: 'Anguilla',
    AL: 'Albanie',
    AM: 'Arménie',
    AO: 'Angola',
    AQ: 'Antarctique',
    AR: 'Argentine',
    AS: 'Samoa américaines',
    AT: 'Autriche',
    AU: 'Australie',
    AW: 'Aruba',
    AX: 'Åland',
    AZ: 'Azerbaïdjan',
    BA: 'Bosnie-Herzégovine',
    BB: 'Barbade',
    BD: 'Bangladesh',
    BE: 'Belgique',
    BF: 'Burkina Faso',
    BG: 'Bulgarie',
    BH: 'Bahreïn',
    BI: 'Burundi',
    BJ: 'Bénin',
    BL: 'Saint-Barthélemy',
    BM: 'Bermudes',
    BN: 'Brunei Darussalam',
    BO: 'Bolivie',
    BQ: 'Bonaire, Saint-Eustache et Saba',
    BR: 'Brésil',
    BS: 'Bahamas',
    BT: 'Bhoutan',
    BV: 'Île Bouvet',
    BW: 'Botswana',
    BY: 'Biélorussie',
    BZ: 'Belize',
    CA: 'Canada',
    CC: 'Îles Cocos',
    CD: 'République démocratique du Congo',
    CF: 'République Centrafricaine',
    CG: 'République du Congo',
    CH: 'Suisse',
    CI: "Côte-d'Ivoire",
    CK: 'Îles Cook',
    CL: 'Chili',
    CM: 'Cameroun',
    CN: 'Chine',
    CO: 'Colombie',
    CR: 'Costa Rica',
    CU: 'Cuba',
    CV: 'Cap-Vert',
    CW: 'Curaçao',
    CX: 'Île Christmas',
    CY: 'Chypre',
    CZ: 'République Tchèque',
    DE: 'Allemagne',
    DJ: 'Djibouti',
    DK: 'Danemark',
    DM: 'Dominique',
    DO: 'République Dominicaine',
    DZ: 'Algérie',
    EC: 'Équateur',
    EE: 'Estonie',
    EG: 'Égypte',
    EH: 'Sahara occidental',
    ER: 'Érythrée',
    ES: 'Espagne',
    ET: 'Éthiopie',
    FI: 'Finlande',
    FJ: 'Fidji',
    FK: 'Îles Malouines',
    FM: 'Micronésie',
    FO: 'Îles Féroé',
    FR: 'France',
    GA: 'Gabon',
    GB: 'Royaume-Uni',
    GD: 'Grenade',
    GE: 'Géorgie',
    GF: 'Guyane française',
    GG: 'Guernesey',
    GH: 'Ghana',
    GI: 'Gibraltar',
    GL: 'Groenland',
    GM: 'Gambie',
    GN: 'Guinée',
    GP: 'Guadeloupe',
    GQ: 'Guinée équatoriale',
    GR: 'Grèce',
    GS: 'Géorgie du Sud-et-les Îles Sandwich du Sud',
    GT: 'Guatemala',
    GU: 'Guam',
    GW: 'Guinée-Bissau',
    GY: 'Guyana',
    HK: 'Hong Kong',
    HM: 'Îles Heard-et-MacDonald',
    HN: 'Honduras',
    HR: 'Croatie',
    HT: 'Haïti',
    HU: 'Hongrie',
    ID: 'Indonésie',
    IE: 'Irlande',
    IL: 'Israël',
    IM: 'Île de Man',
    IN: 'Inde',
    IO: 'Océan Indien Britannique',
    IQ: 'Irak',
    IR: 'Iran',
    IS: 'Islande',
    IT: 'Italie',
    JE: 'Jersey',
    JM: 'Jamaïque',
    JO: 'Jordanie',
    JP: 'Japon',
    KE: 'Kenya',
    KG: 'Kirghizistan',
    KH: 'Cambodge',
    KI: 'Kiribati',
    KM: 'Comores',
    KN: 'Saint-Christophe-et-Niévès',
    KP: 'Corée du Nord',
    KR: 'Corée du Sud',
    KW: 'Koweït',
    KY: 'Îles Caïmans',
    KZ: 'Kazakhstan',
    LA: 'Laos',
    LB: 'Liban',
    LC: 'Sainte-Lucie',
    LI: 'Liechtenstein',
    LK: 'Sri Lanka',
    LR: 'Libéria',
    LS: 'Lesotho',
    LT: 'Lituanie',
    LU: 'Luxembourg',
    LV: 'Lettonie',
    LY: 'Libye',
    MA: 'Maroc',
    MC: 'Monaco',
    MD: 'Moldavie',
    ME: 'Monténégro',
    MF: 'Saint-Martin (partie française)',
    MG: 'Madagascar',
    MH: 'Îles Marshall',
    MK: 'Macédoine du Nord',
    ML: 'Mali',
    MM: 'Myanmar',
    MN: 'Mongolie',
    MO: 'Macao',
    MP: 'Îles Mariannes du Nord',
    MQ: 'Martinique',
    MR: 'Mauritanie',
    MS: 'Montserrat',
    MT: 'Malte',
    MU: 'Maurice',
    MV: 'Maldives',
    MW: 'Malawi',
    MX: 'Mexique',
    MY: 'Malaisie',
    MZ: 'Mozambique',
    NA: 'Namibie',
    NC: 'Nouvelle-Calédonie',
    NE: 'Niger',
    NF: 'Île Norfolk',
    NG: 'Nigéria',
    NI: 'Nicaragua',
    NL: 'Pays-Bas',
    NO: 'Norvège',
    NP: 'Népal',
    NR: 'Nauru',
    NU: 'Niué',
    NZ: 'Nouvelle-Zélande',
    OM: 'Oman',
    PA: 'Panama',
    PE: 'Pérou',
    PF: 'Polynésie française',
    PG: 'Papouasie-Nouvelle-Guinée',
    PH: 'Philippines',
    PK: 'Pakistan',
    PL: 'Pologne',
    PM: 'Saint-Pierre-et-Miquelon',
    PN: 'Îles Pitcairn',
    PR: 'Porto Rico',
    PS: 'Palestine',
    PT: 'Portugal',
    PW: 'Palaos',
    PY: 'Paraguay',
    QA: 'Qatar',
    RE: 'Réunion',
    RO: 'Roumanie',
    RS: 'Serbie',
    RU: 'Russie',
    RW: 'Rwanda',
    SA: 'Arabie Saoudite',
    SB: 'Îles Salomon',
    SC: 'Seychelles',
    SD: 'Soudan',
    SE: 'Suède',
    SG: 'Singapour',
    SH: 'Sainte-Hélène',
    SI: 'Slovénie',
    SJ: 'Svalbard et Île Jan Mayen',
    SK: 'Slovaquie',
    SL: 'Sierra Leone',
    SM: 'Saint-Marin',
    SN: 'Sénégal',
    SO: 'Somalie',
    SR: 'Suriname',
    SS: 'Soudan du Sud',
    ST: 'São Tomé-et-Principe',
    SV: 'El Salvador',
    SX: 'Saint-Martin (partie néerlandaise)',
    SY: 'Syrie',
    SZ: "Royaume d'Eswatini",
    TC: 'Îles Turques-et-Caïques',
    TD: 'Tchad',
    TF: 'Terres australes françaises',
    TG: 'Togo',
    TH: 'Thaïlande',
    TJ: 'Tadjikistan',
    TK: 'Tokelau',
    TL: 'Timor-Leste',
    TM: 'Turkménistan',
    TN: 'Tunisie',
    TO: 'Tonga',
    TR: 'Turquie',
    TT: 'Trinité-et-Tobago',
    TV: 'Tuvalu',
    TW: 'Taïwan',
    TZ: 'République unie de Tanzanie',
    UA: 'Ukraine',
    UG: 'Ouganda',
    UM: 'Îles mineures éloignées des États-Unis',
    US: "États-Unis d'Amérique",
    UY: 'Uruguay',
    UZ: 'Ouzbékistan',
    VA: 'Saint-Siège (Vatican)',
    VC: 'Saint-Vincent-et-les-Grenadines',
    VE: 'Venezuela',
    VG: 'Îles vierges britanniques',
    VI: 'Îles vierges américaines',
    VN: 'Vietnam',
    VU: 'Vanuatu',
    WF: 'Wallis-et-Futuna',
    WS: 'Samoa',
    XK: 'Kosovo',
    YE: 'Yémen',
    YT: 'Mayotte',
    ZA: 'Afrique du Sud',
    ZM: 'Zambie',
    ZW: 'Zimbabwe'
}

const localVue = createLocalVue()

describe('components/UI/AppSelectCountry.vue', () => {
    let wrapper: any

    beforeEach(() => {
        wrapper = shallowMount(AppSelectCountry, {
            localVue,
            mocks: {
                $t: i18n.$t,
                $tc: i18n.$tc,
                $i18n: {
                    locale: 'fr'
                }
            }
        })
    })

    it('is a Vue instance with page info', () => {
        expect(wrapper.vm).toBeTruthy()
        expect(wrapper.vm.listCountries).toEqual(listCountriesFR)
    })

    it('fetch all countries', () => {
        const allCountries = wrapper.vm.fetchCountries()
        expect(allCountries).toEqual({ results: listCountriesFR })
    })

    it('fetch filtered countries', () => {
        let filteredCountries = wrapper.vm.fetchCountries('Fr')
        const listCountryWithFr = {
            CF: 'République Centrafricaine',
            FR: 'France',
            GF: 'Guyane française',
            MF: 'Saint-Martin (partie française)',
            PF: 'Polynésie française',
            TF: 'Terres australes françaises',
            ZA: 'Afrique du Sud'
        }
        expect(filteredCountries).toEqual({ results: listCountryWithFr })

        filteredCountries = wrapper.vm.fetchCountries('Ta')
        const listCountryWithTa = {
            AF: 'Afghanistan',
            AQ: 'Antarctique',
            BQ: 'Bonaire, Saint-Eustache et Saba',
            BT: 'Bhoutan',
            CR: 'Costa Rica',
            EH: 'Sahara occidental',
            GI: 'Gibraltar',
            IO: 'Océan Indien Britannique',
            IT: 'Italie',
            KG: 'Kirghizistan',
            KZ: 'Kazakhstan',
            MR: 'Mauritanie',
            PK: 'Pakistan',
            QA: 'Qatar',
            TJ: 'Tadjikistan',
            TM: 'Turkménistan',
            TW: 'Taïwan',
            TZ: 'République unie de Tanzanie',
            UM: 'Îles mineures éloignées des États-Unis',
            US: "États-Unis d'Amérique",
            UZ: 'Ouzbékistan',
            VG: 'Îles vierges britanniques'
        }
        expect(filteredCountries).toEqual({ results: listCountryWithTa })
    })

    it('emit input', () => {
        expect(wrapper.emitted().input).toBeFalsy()
        wrapper.vm.inputVal('FR')
        expect(wrapper.emitted().input[0]).toEqual(['FR'])
    })
    it('emit change', () => {
        expect(wrapper.emitted().change).toBeFalsy()
        wrapper.vm.changeVal('EN')
        expect(wrapper.emitted().change[0]).toEqual(['EN'])
    })
})
