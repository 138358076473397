import { createLocalVue, shallowMount } from '@vue/test-utils'
import Cookies from 'js-cookie'
import AppAlert from '@/components/UI/AppAlert.vue'

const localVue = createLocalVue()

describe('components/UI/AppAlert.vue', () => {
    let wrapper: any

    beforeEach(() => {
        wrapper = shallowMount(AppAlert, {
            localVue
        })
    })

    it('is a Vue instance', () => {
        expect(wrapper.vm).toBeTruthy()
        expect(wrapper.vm.type).toBe('info')
        expect(wrapper.vm.showClose).toBeFalsy()
        expect(wrapper.vm.rounded).toBeTruthy()
        expect(wrapper.vm.shadowed).toBeTruthy()
        expect(wrapper.vm.bordered).toBeTruthy()
        expect(wrapper.vm.visible).toBeTruthy()
    })
    it('should mount visible when it has identifier', () => {
        wrapper = shallowMount(AppAlert, {
            propsData: {
                identifier: 'id'
            },
            localVue
        })
        expect(wrapper.vm.visible).toBeTruthy()
    })

    it('initialize with type', async () => {
        expect(wrapper.vm.color).toBe('chargemap')

        // Warning
        await wrapper.setProps({
            type: 'warning'
        })
        expect(wrapper.vm.color).toBe('yellow')

        // Info
        await wrapper.setProps({
            type: 'info'
        })
        expect(wrapper.vm.color).toBe('chargemap')

        // Error
        await wrapper.setProps({
            type: 'error'
        })
        expect(wrapper.vm.color).toBe('red')

        // Validate
        await wrapper.setProps({
            type: 'validate'
        })
        expect(wrapper.vm.color).toBe('green')

        // Other value
        await wrapper.setProps({
            type: 'unknown'
        })
        expect(wrapper.vm.color).toBe('chargemap')
    })

    it('emits confirm', () => {
        expect(wrapper.emitted().callConfirm).toBeFalsy()
        wrapper.vm.callConfirm()
        expect(wrapper.emitted().callConfirm).toBeTruthy()
    })

    it('should not stock to storage', async () => {
        const setSpy = jest.spyOn(Cookies, 'set')
        await wrapper.setProps({ identifier: null })
        localVue.nextTick()
        wrapper.vm.stockVisit()
        expect(setSpy).not.toBeCalled()
    })

    it('should stock to storage', async () => {
        await wrapper.setProps({ identifier: 'identifier' })
        const setSpy = jest.spyOn(Cookies, 'set')

        wrapper.vm.stockVisit()

        expect(setSpy).toBeCalledWith('identifier', 'true', {
            expires: 365
        })
    })

    it('should stock if identifier', async () => {
        wrapper.vm.stockVisit = jest.fn()
        await wrapper.setProps({ identifier: 'identifier' })
        wrapper.vm.callConfirm()
        wrapper.vm.dismiss()
        expect(wrapper.vm.stockVisit).toHaveBeenCalledTimes(2)
    })
    it('should not stock if no identifier', async () => {
        await wrapper.setProps({ identifier: null })
        wrapper.vm.stockVisit = jest.fn()
        wrapper.vm.callConfirm()
        wrapper.vm.dismiss()
        wrapper.vm.callAction()
        expect(wrapper.vm.stockVisit).not.toHaveBeenCalled()
    })

    it('emits action', () => {
        expect(wrapper.emitted().callAction).toBeFalsy()
        wrapper.vm.callAction()
        expect(wrapper.emitted().callAction).toBeTruthy()
    })

    it('hide alert', () => {
        expect(wrapper.vm.visible).toBeTruthy()
        wrapper.vm.dismiss()
        expect(wrapper.vm.visible).toBeFalsy()
    })
})
