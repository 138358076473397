export function axiosWrapper(
    this: any,
    method: string,
    url: string,
    payload: any = {},
    headers: any = {}
): Promise<any> {
    return this.$axios[`$${method}`](url, payload, headers)
        .then((res: any) => this.$toCamel(res))
        .catch((e: any) => {
            throw e
        })
}
export function axiosListWrapper(
    this: any,
    url: string,
    payload: any = {},
    filters: any = {}
): Promise<any> {
    const { pages, searchField } = filters
    const offset = (pages.index - 1) * pages.perPage || (0 as number)
    const limit = pages.perPage || 20
    const keyword = searchField || (null as null | string)
    const params = { ...payload, offset, limit, keyword }
    return this.$axios
        .get(url, { params })
        .then((response: any) => {
            const resDTO = response.data.items
            const count = Number(response.headers['x-limit'])
            const totalCount = Number(response.headers['x-total-count'])
            const previous = response.headers['x-previous-page']
            const next = response.headers['x-next-page']
            const items = this.$toCamel(resDTO)
            return {
                count,
                totalCount,
                items,
                previous,
                next
            }
        })
        .catch((e: any) => {
            throw e
        })
}
