import { defineStore } from 'pinia'
import { DateTime } from 'luxon'
import orderBy from 'lodash/orderBy'
import { ref } from 'vue'
import {
    AggregationType,
    ChargeExportType,
    ConnectedCableCharge,
    DomesticConnectedCableExport,
    ExportFileType,
    InternalDomesticCharge,
    RefundStatus,
    InternalCharge,
    ExternalCharge,
    DateAvailable,
    EventChargeType
} from '../interfaces/charge'
import { TypeZone } from '~/interfaces/zone'
import { ChargeFilter } from '@/interfaces/filters'
import { keysToCamel } from '~/plugins/json/utils'

export const convertPaginate = (index: number, perPage: number) => {
    const offset = (index - 1) * perPage
    const limit = perPage
    return { offset, limit }
}
export const convertDate = (
    dateStartRaw: string | undefined,
    dateEndRaw: string | undefined
) => {
    let minStartDate = null
    let maxStartDate = null
    if (!dateStartRaw) {
        return { minStartDate, maxStartDate }
    }
    minStartDate = DateTime.fromISO(dateStartRaw).toISO()
    if (dateEndRaw) {
        maxStartDate = DateTime.fromISO(dateEndRaw).plus({ days: 1 }).toISO()
    }
    return { minStartDate, maxStartDate }
}

const useChargeStore = defineStore('charge', () => {
    const externalCharges = ref([] as ExternalCharge[])
    const externalChargesCount = ref(0 as number)
    const internalCharges = ref([] as InternalCharge[])
    const internalChargesCount = ref(0 as number)
    const domesticCharges = ref([] as InternalDomesticCharge[])
    const domesticChargesCount = ref(0 as number)
    const chargingCharges = ref([] as InternalCharge[])
    const chargingChargesCount = ref(0 as number)
    const fleetCharges = ref([] as ExternalCharge[])
    const fleetChargesCount = ref(0 as number)
    const connectedCableCharges = ref([] as ConnectedCableCharge[])
    const connectedCableChargesCount = ref(0 as number)

    const hasCharges = () => {
        return (
            externalChargesCount.value > 0 ||
            internalChargesCount.value > 0 ||
            domesticChargesCount.value > 0 ||
            chargingChargesCount.value > 0
        )
    }

    const hasChargingCharges = () => chargingChargesCount.value > 0

    const addCharge = (charge: InternalCharge, filterPerPage: number) => {
        chargingCharges.value.unshift(charge)
        chargingChargesCount.value += 1
        while (chargingCharges.value.length > filterPerPage) {
            chargingCharges.value.pop()
        }
    }

    const updateCharge = (charge: InternalCharge) => {
        const internalChargeWithSameId = (
            a: InternalCharge,
            b: InternalCharge
        ) => (a.id === b.id ? b : a)

        chargingCharges.value = chargingCharges.value?.map(
            (t: InternalCharge) => internalChargeWithSameId(t, charge)
        )
    }

    const deleteCharge = (charge: InternalCharge, filterPerPage: number) => {
        const removeIndex = chargingCharges.value
            .map((sess: InternalCharge) => sess.pass?.rfidUid)
            .indexOf(charge.pass?.rfidUid)
        if (removeIndex >= 0) {
            chargingCharges.value.splice(removeIndex, 1)
            chargingChargesCount.value -= 1
        }
        internalCharges.value.unshift(charge)
        internalChargesCount.value += 1
        while (internalCharges.value.length > filterPerPage) {
            internalCharges.value.pop()
        }
    }

    const resetCharge = () => {
        internalCharges.value = []
        internalChargesCount.value = 0
        externalCharges.value = []
        externalChargesCount.value = 0
        chargingCharges.value = []
        chargingChargesCount.value = 0
        domesticCharges.value = []
        domesticChargesCount.value = 0
    }

    const manageSubscribe = (payload: {
        filterPerPage: number
        eventCharge: { type: string; charge: InternalCharge }
    }) => {
        const { filterPerPage, eventCharge } = payload
        const charge = keysToCamel(eventCharge.charge) as InternalCharge

        if (eventCharge.type === EventChargeType.STARTED) {
            return addCharge(charge, filterPerPage)
        }
        if (eventCharge.type === EventChargeType.UPDATED) {
            return updateCharge(charge)
        }
        if (eventCharge.type === EventChargeType.FINISHED) {
            return deleteCharge(charge, filterPerPage)
        }

        throw new Error(
            `reload charges with unknown type ${
                eventCharge.type
            } on charge rfidUid ${
                eventCharge.charge.pass?.rfidUid || 'unknown'
            }`
        )
    }

    const fetchCharges = (
        context: any,
        payload: {
            idBU?: number | string
            idOrga?: number | string
            finished: number | undefined
            isInternalBU?: boolean
            zoneType?: TypeZone | undefined
            chargeboxId?: number | string
            filters: ChargeFilter
            isSuperAdminView?: boolean
        }
    ) => {
        const { dateRange, searchField, pages, refundStatus } = payload.filters

        let params = {
            finished: payload.finished,
            zone_type: payload.zoneType,
            refund_status: undefined as RefundStatus[] | undefined,
            chargebox_id: undefined as number | string | undefined,
            offset: undefined as number | undefined,
            limit: undefined as number | undefined,
            keyword: undefined as string | undefined,
            min_start_date: undefined as string | undefined | null,
            max_start_date: undefined as string | undefined | null,
            order_by: 'date,desc'
        }
        const { offset, limit } = convertPaginate(pages.index, pages.perPage)
        if (offset) {
            params = { ...params, offset }
        }
        if (limit) {
            params = { ...params, limit }
        }
        if (refundStatus) {
            const refundStatusWithoutAllFilter = refundStatus.filter(
                (filter) => filter !== RefundStatus.ALL
            )
            params = {
                ...params,
                refund_status: refundStatusWithoutAllFilter
            }
        }
        if (dateRange) {
            const { minStartDate, maxStartDate } = convertDate(
                dateRange[0],
                dateRange[1]
            )
            if (minStartDate) {
                params = { ...params, min_start_date: minStartDate }
            }
            if (maxStartDate) {
                params = { ...params, max_start_date: maxStartDate }
            }
        }

        const keyword = searchField || null
        if (keyword) {
            params = { ...params, keyword }
        }

        const chargeBox = payload.chargeboxId || undefined
        if (chargeBox) {
            params = { ...params, chargebox_id: chargeBox }
        }

        let urlAPI = 'charges'

        if (payload.isInternalBU) {
            urlAPI = 'fleet-charges'
        }

        if (!payload.isSuperAdminView) {
            urlAPI = `business-units/${payload.idBU}/charges`
            if (payload.isInternalBU) {
                urlAPI = `business-units/${payload.idBU}/fleet-charges`
            }
        }

        return context.$axios
            .get(`/supervisionapi/${urlAPI}`, {
                params
            })
            .then((response: any) => ({
                count: response?.headers
                    ? Number(response?.headers['x-total-count'])
                    : 0,
                items: keysToCamel(response?.data?.items) || []
            }))
            .catch((e: any) => {
                throw e
            })
    }

    const fetchChargingCharges = (
        context: any,
        payload: {
            idBU: number | string
            idOrga: number | string
            isInternalBU: boolean
            filters: ChargeFilter
        }
    ) => {
        return fetchCharges(context, {
            ...payload,
            finished: 0,
            zoneType: payload.filters.zoneType || undefined,
            filters: {
                ...payload.filters,
                dateRange: undefined
            } as ChargeFilter
        }).then((response: { count: number; items: InternalCharge[] }) => {
            const charges = orderBy(response.items, 'startDate', 'desc')
            chargingCharges.value = charges
            chargingChargesCount.value = response.count
        })
    }

    const fetchInternalCharges = (
        context: any,
        payload: {
            idBU?: number
            idOrga?: number
            isInternalBU: boolean
            filters: ChargeFilter
            isSuperAdminView: boolean
        }
    ) => {
        return fetchCharges(context, {
            ...payload,
            finished: 1,
            zoneType: TypeZone.PRIVATE,
            filters: { ...payload.filters }
        }).then((response: { count: number; items: InternalCharge[] }) => {
            const charges = orderBy(response.items, 'startDate', 'desc')
            internalCharges.value = charges
            internalChargesCount.value = response.count
        })
    }

    const fetchDomesticCharges = (
        context: any,
        payload: {
            idBU?: number
            isInternalBU: boolean
            filters: ChargeFilter
            isSuperAdminView: boolean
        }
    ) => {
        return fetchCharges(context, {
            ...payload,
            finished: 1,
            zoneType: TypeZone.DOMESTIC,
            filters: { ...payload.filters }
        }).then(
            (response: { count: number; items: InternalDomesticCharge[] }) => {
                const charges = orderBy(response.items, 'startDate', 'desc')
                domesticCharges.value = charges
                domesticChargesCount.value = response.count
            }
        )
    }

    const fetchConnectedCableCharges = (
        context: any,
        payload: {
            idBU?: number
            isInternalBU: boolean
            filters: ChargeFilter
            isSuperAdminView: boolean
        }
    ) => {
        return fetchCharges(context, {
            ...payload,
            finished: 1,
            zoneType: TypeZone.CONNECTED_CABLE,
            filters: { ...payload.filters }
        }).then(
            (response: { count: number; items: ConnectedCableCharge[] }) => {
                const charges = orderBy(response.items, 'startDate', 'desc')
                connectedCableCharges.value = charges
                connectedCableChargesCount.value = response.count
            }
        )
    }

    const fetchById = (
        context: any,
        payload: {
            id: string | number
            idBU: number
            isFleetCharges: boolean | null
        }
    ) => {
        const { idBU, id, isFleetCharges } = payload
        let urlAPI = `/supervisionapi/business-units/${idBU}/charges/${id}`
        if (isFleetCharges) {
            urlAPI = `/supervisionapi/business-units/${idBU}/fleet-charges/${id}`
        }
        return context.$axios
            .$get(urlAPI)
            .then((res: any) => keysToCamel(res))
            .catch((e: any) => {
                throw e.response
            })
    }

    const fetchCoreRoamingById = (
        context: any,
        payload: {
            id: string | number
            idBU: number
        }
    ) => {
        const { id, idBU } = payload

        return context.$axios
            .$get(
                `/supervisionapi/business-units/${idBU}/charges/core-roaming/${id}`
            )
            .then((res: any) => keysToCamel(res))
            .catch((e: any) => {
                throw e.response
            })
    }

    const updateChargeStatus = (
        context: any,
        payload: {
            id: string | number
            refundStatus: RefundStatus.ALLOWED | RefundStatus.BLOCKED
        }
    ) => {
        return context.$axios
            .$patch(`/refundyapi/charges/${payload.id}`, {
                refund_status: payload.refundStatus
            })
            .then((res: any) => keysToCamel(res))
            .catch((e: any) => {
                throw e
            })
    }

    const fetchExternalCharges = (
        context: any,
        payload: {
            idBU?: number
            filters: ChargeFilter
            isSuperAdminView: boolean | undefined
        }
    ) => {
        const {
            isSuccess: success,
            dateRange,
            searchField,
            pages,
            billed
        } = payload.filters
        let params = {
            offset: undefined as number | undefined,
            limit: undefined as number | undefined,
            keyword: undefined as string | undefined,
            min_start_date: undefined as string | undefined | null,
            max_start_date: undefined as string | undefined | null,
            success,
            billed,
            order_by: 'date,desc'
        }

        const { offset, limit } = convertPaginate(pages.index, pages.perPage)
        if (offset) {
            params = { ...params, offset }
        }
        if (limit) {
            params = { ...params, limit }
        }
        if (dateRange) {
            const { minStartDate, maxStartDate } = convertDate(
                dateRange[0],
                dateRange[1]
            )
            if (minStartDate) {
                params = { ...params, min_start_date: minStartDate }
            }
            if (maxStartDate) {
                params = { ...params, max_start_date: maxStartDate }
            }
        }

        const keyword = searchField || null
        if (keyword) {
            params = { ...params, keyword }
        }

        let urlAPI = '/fleetapi/charges'

        if (!payload.isSuperAdminView) {
            urlAPI = `/fleetapi/business-units/${payload.idBU}/charges`
        }

        return context.$axios
            .$get(urlAPI, {
                params
            })
            .then((res: any) => {
                externalCharges.value = keysToCamel(
                    res.items
                ) as ExternalCharge[]
                externalChargesCount.value = res.count
                return externalCharges.value
            })
            .catch((e: any) => {
                throw new Error(e.response.message)
            })
    }

    const fetchExternalCharge = (
        context: any,
        payload: { idBU: string; idCharge: string }
    ) => {
        const { idBU, idCharge } = payload
        return context.$axios
            .$get(`/fleetapi/business-units/${idBU}/charges/${idCharge}`)
            .then((res: any) => keysToCamel(res) as ExternalCharge)
            .catch((e: any) => {
                throw new Error(e.response.message)
            })
    }

    const fetchExportDateCharges = (
        context: any,
        payload: {
            idBU: string
            idOrga: string
            isInternal: boolean
        }
    ) => {
        const { idBU, isInternal } = payload
        const supervisionUrl = `supervisionapi/business-units/${idBU}/fleet-charges/dates-available`
        const fleetUrl = `/fleetapi/business-units/${idBU}/charges/dates-available`
        const url = isInternal ? supervisionUrl : fleetUrl

        const params = {}

        return context.$axios
            .$get(url, { params })
            .then((res: Array<DateAvailable> | DateAvailable) => {
                if (!Array.isArray(res)) {
                    return [res]
                }
                return res
            })
            .catch((e: any) => {
                throw new Error(e.response?.message || e)
            })
    }

    const fetchExportDateBilledChargesExpenses = (
        context: any,
        payload: {
            idBU: string
            idOrga: string
        }
    ) => {
        const params = {}

        return context.$axios
            .$get(
                `supervisionapi/business-units/${payload.idBU}/billed-charges-expenses/dates-available`,
                { params }
            )
            .then((res: Array<DateAvailable> | DateAvailable) => {
                const dateAvailable = context.$toCamel(res)
                if (!Array.isArray(dateAvailable)) {
                    return [dateAvailable]
                }
                return dateAvailable
            })
            .catch((e: any) => {
                throw e
            })
    }

    const fetchExport = (
        context: any,
        payload: {
            idOrga: string
            idBU?: number
            startDate: string
            endDate: string
            exportAllBusinessUnits: boolean
            chargeTypes: Array<ChargeExportType | DomesticConnectedCableExport>
            aggregationTypes: AggregationType[]
            fileType: ExportFileType
            supervised: boolean
        }
    ) => {
        const {
            idOrga,
            idBU,
            startDate,
            endDate,
            chargeTypes,
            fileType,
            supervised
        } = payload

        let url = `exportyapi/organizations/${idOrga}`

        if (idBU) {
            url += `/business-units/${idBU}`
        }

        if (supervised) {
            url += '/supervised_export'
        } else {
            url += '/fleet_export'
        }

        const params = {
            start_date: startDate,
            end_date: endDate,
            charge_types: chargeTypes,
            aggregation_filters: payload.aggregationTypes,
            export_all_business_units: payload.exportAllBusinessUnits,
            file_type: fileType
        }

        return context.$axios
            .$get(url, { params })
            .then((res: any) => context.$toCamel(res))
            .catch((e: any) => {
                throw e
            })
    }

    return {
        externalCharges,
        externalChargesCount,
        internalCharges,
        internalChargesCount,
        domesticCharges,
        domesticChargesCount,
        chargingCharges,
        chargingChargesCount,
        fleetCharges,
        fleetChargesCount,
        connectedCableCharges,
        connectedCableChargesCount,
        hasCharges,
        hasChargingCharges,
        addCharge,
        updateCharge,
        deleteCharge,
        resetCharge,
        manageSubscribe,
        fetchCharges,
        fetchChargingCharges,
        fetchInternalCharges,
        fetchDomesticCharges,
        fetchConnectedCableCharges,
        fetchById,
        fetchCoreRoamingById,
        updateChargeStatus,
        fetchExternalCharges,
        fetchExternalCharge,
        fetchExportDateCharges,
        fetchExportDateBilledChargesExpenses,
        fetchExport
    }
})

type ChargeStore = Omit<
    ReturnType<typeof useChargeStore>,
    keyof ReturnType<typeof defineStore>
>

export default useChargeStore

export type { ChargeStore }
