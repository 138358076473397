import { defineStore } from 'pinia'
import { ref } from 'vue'
import { Filters } from '~/interfaces/filters'

const useMobileFilterStore = defineStore('mobileFilter', () => {
    const filters = ref([] as Filters[])
    const showBottomSheet = ref(false as boolean)
    const showSearchBar = ref(false as boolean)
    const hasSearch = ref(false as boolean)
    const toUpdate = ref(false as boolean)

    const getFilter = (filter: any) =>
        filters.value.find((f: any) => f.id === filter.id)

    const addFilter = (filter: Filters) => {
        filters.value.push(filter)
    }

    const removeFilter = (idFilter: number) => {
        filters.value = filters.value.filter((f: any) => f.id !== idFilter)
    }

    const resetFilter = () => {
        filters.value.forEach((filter: Filters) => {
            filter.value = null
        })
    }

    const resetFilters = (newFilters: Filters[] = []) => {
        filters.value = newFilters || []
    }

    const updateFilter = (filter: Filters) => {
        const filterMatch = getFilter(filter)
        if (filterMatch) {
            filterMatch.value = filter.value
        }
    }

    const toggleBottomSheet = () => {
        showBottomSheet.value = !showBottomSheet.value
    }

    const toggleSearchBar = () => {
        showSearchBar.value = !showSearchBar.value
    }

    return {
        filters,
        showBottomSheet,
        showSearchBar,
        hasSearch,
        toUpdate,
        getFilter,
        addFilter,
        removeFilter,
        resetFilter,
        resetFilters,
        updateFilter,
        toggleBottomSheet,
        toggleSearchBar
    }
})

type MobileFilterStore = Omit<
    ReturnType<typeof useMobileFilterStore>,
    keyof ReturnType<typeof defineStore>
>

export default useMobileFilterStore

export type { MobileFilterStore }
