import { defineStore } from 'pinia'
import {
    BUCloudStatisticsJSON,
    BUCloudStatistics,
    BUSupervisionStatistics,
    BUSupervisionStatisticsJSON
} from '@/interfaces/statistics'

const useStatisticsStore = defineStore('statistics', () => {
    const fetchCloudStatistics = (
        context: any,
        payload: { idOrga: number; idBU: number }
    ): Promise<BUCloudStatistics> => {
        const { idOrga, idBU } = payload
        let url = `/cloudapi/organizations/${idOrga}`
        if (idBU) {
            url += `/business-units/${idBU}`
        }
        url += '/statistics'
        return context.$axios
            .$get(url)
            .then((res: BUCloudStatisticsJSON) => {
                const stats: BUCloudStatistics = context.$toCamel(res)
                return stats
            })
            .catch((e: any) => {
                throw e
            })
    }

    const fetchSupervisionStatistics = (
        context: any,
        payload: { idOrga: number; idBU: number }
    ): Promise<BUSupervisionStatistics> => {
        const { idOrga, idBU } = payload
        let url = `/supervisionapi/organizations/${idOrga}`
        if (idBU) {
            url = `/supervisionapi/business-units/${idBU}`
        }
        url += '/statistics'
        return context.$axios
            .$get(url)
            .then((res: BUSupervisionStatisticsJSON) => context.$toCamel(res))
            .catch((e: any) => {
                throw e
            })
    }

    return {
        fetchCloudStatistics,
        fetchSupervisionStatistics
    }
})

type StatisticsStore = Omit<
    ReturnType<typeof useStatisticsStore>,
    keyof ReturnType<typeof defineStore>
>

export default useStatisticsStore

export type { StatisticsStore }
