import { defineStore } from 'pinia'
import { DateTime } from 'luxon'
import { Authorization, AuthorizationDTO } from '~/interfaces/authorization'
import { ChargeFilter } from '~/interfaces/filters'

const useAuthorizationStore = defineStore('authorization', () => {
    const convertDate = (dateStartRaw?: string, dateEndRaw?: string) => {
        if (!dateStartRaw) {
            return {}
        }
        const dateStart = DateTime.fromISO(dateStartRaw)
        const minStartDate = dateStart.toISO()
        if (!dateEndRaw) {
            return { min_start_date: minStartDate }
        }
        const dateEnd = DateTime.fromISO(dateEndRaw).plus({ days: 1 })
        const maxStartDate = dateEnd.toISO()
        return { min_start_date: minStartDate, max_start_date: maxStartDate }
    }

    const fetchAuthorizations = (
        context: any,
        payload: { idBU: number; filters: ChargeFilter }
    ): Promise<{ count: number; authorizations: Authorization[] }> => {
        const { idBU, filters } = payload
        const { dateRange, pages, searchField, isAccepted, isKnown } = filters
        const offset = (pages.index - 1) * pages.perPage || (0 as number)
        const limit = pages.perPage || 20
        const keyword = searchField || (null as null | string)
        const accepted = isAccepted
        const known_pass = isKnown
        let params: any = { offset, limit, keyword, known_pass, accepted }
        if (dateRange) {
            params = {
                ...params,
                ...convertDate(dateRange[0], dateRange[1])
            }
        }
        const url = `/supervisionapi/business-units/${idBU}/authorizations`
        return context.$axios
            .get(url, { params })
            .then((response: any) => {
                const resDTO = response.data.items as AuthorizationDTO[]
                const count = Number(response.headers['x-total-count'])
                const authorizations = context.$toCamel(
                    resDTO
                ) as Authorization[]
                return {
                    count,
                    authorizations
                }
            })
            .catch((e: any) => {
                throw e
            })
    }

    return {
        convertDate,
        fetchAuthorizations
    }
})

type AuthorizationStore = Omit<
    ReturnType<typeof useAuthorizationStore>,
    keyof ReturnType<typeof defineStore>
>

export default useAuthorizationStore

export type { AuthorizationStore }
