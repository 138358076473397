import { ref } from 'vue'
import { defineStore } from 'pinia'
import { DateTime } from 'luxon'
import { ExpenseReport, ExpenseReportJSON } from '@/interfaces/expenseReport'
import { BillAndExpenseReportsFilter } from '~/interfaces/filters'
import { ExportType } from '~/interfaces/other'

const useExpenseReportStore = defineStore('exportReport', () => {
    const expenseReports = ref([] as ExpenseReport[])
    const expenseReportsCount = ref(null as number | null)
    const expenseReportsSelected = ref([] as string[])

    const convertDate = (
        dateStartRaw: string | undefined,
        dateEndRaw: string | undefined
    ) => {
        let minStartDate = null
        let maxStartDate = null
        if (!dateStartRaw) {
            return { minStartDate, maxStartDate }
        }
        minStartDate = DateTime.fromISO(dateStartRaw).toISO()
        if (dateEndRaw) {
            maxStartDate = DateTime.fromISO(dateEndRaw)
                .plus({ days: 1 })
                .toISO()
        }
        return { minStartDate, maxStartDate }
    }

    const setExpenseReports = (newExpenseReports: ExpenseReport[]) => {
        expenseReports.value = newExpenseReports
    }

    const setExpenseReportsCount = (count: number) => {
        expenseReportsCount.value = count
    }

    const fetchExpenseReports = (
        context: any,
        payload: {
            idOrga: number | undefined
            idBU: number | undefined
            filters: BillAndExpenseReportsFilter
        }
    ) => {
        const { filters } = payload
        const { pages, dateRange, state, keyword } = filters
        const { idOrga, idBU } = payload
        const offset = (pages.index - 1) * pages.perPage || 0
        const limit = pages.perPage || 20
        let params = {
            offset,
            limit,
            min_start_date: null as string | null,
            max_start_date: null as string | null,
            state: state || null,
            keyword
        }

        if (dateRange) {
            const { minStartDate, maxStartDate } = convertDate(
                dateRange[0],
                dateRange[1]
            )
            if (minStartDate) {
                params = { ...params, min_start_date: minStartDate }
            }
            if (maxStartDate) {
                params = { ...params, max_start_date: maxStartDate }
            }
        }

        let url = `refundyapi`
        if (idOrga) {
            url += `/organizations/${idOrga}`
        }
        if (idBU) {
            url += `/business-units/${idBU}`
        }
        url += `/expense_reports`

        return context.$axios
            .get(url, { params })
            .then(
                (res: {
                    headers: {
                        'x-total-count': string
                    }
                    data: {
                        items: ExpenseReportJSON
                    }
                }) => {
                    const result = context.$toCamel(res.data)
                    setExpenseReports(result.items)
                    const count = Number(res.headers['x-total-count'])
                    setExpenseReportsCount(count)
                    return result
                }
            )
            .catch((e: any) => {
                throw e
            })
    }

    const fetchBatchExpenseReportExport = (
        context: any,
        payload: {
            idOrga: string | undefined
            idBU: string | undefined
            type: ExportType | undefined
        }
    ) => {
        const { idOrga, idBU, type } = payload
        let url = '/refundyapi'
        if (idOrga) {
            url += `/organizations/${idOrga}`
        }
        if (idBU) {
            url += `/business-units/${idBU}`
        }

        return context.$axios
            .get(`${url}/expense_reports/batch-export`, {
                params: {
                    expense_reports_nos: expenseReportsSelected.value,
                    file_type: type
                }
            })
            .then((res: any) => res)
            .catch((e: any) => {
                throw e
            })
    }

    return {
        setExpenseReports,
        setExpenseReportsCount,
        fetchExpenseReports,
        fetchBatchExpenseReportExport,
        expenseReports,
        expenseReportsCount,
        expenseReportsSelected
    }
})

type ExpenseReportStore = Omit<
    ReturnType<typeof useExpenseReportStore>,
    keyof ReturnType<typeof defineStore>
>

export default useExpenseReportStore

export type { ExpenseReportStore }
