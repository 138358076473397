import {
    PaymentMethodsAvailable,
    Functionality,
    Organization
} from '../../../interfaces/organization'
import { OrganizationRight } from '~/interfaces/user'

const organizations = {
    organizations: [
        {
            id: 1,
            activated: true,
            billOtherOrganizationBu: false,
            name: 'Chargemap',
            address: '7 allée Cérès',
            postalCode: '67200',
            city: 'Strasbourg',
            country: 'FR',
            enableCallForFunds: true,
            notifyCollaborators: false,
            mainContact: {
                id: 1898,
                email: 'adresse@chargemap.com',
                firstName: 'firstname',
                lastName: 'lastName',
                phone: '0606060606'
            },
            access: [
                Functionality.FLEET,
                Functionality.SUPERVISION,
                Functionality.OPERATOR,
                Functionality.BLOCKING_PASS
            ],
            buCount: 3,
            paymentTerm: 0,
            pipedriveId: 1,
            accountManager: null,
            paymentMethodsAvailable: [PaymentMethodsAvailable.DEBIT]
        },
        {
            id: 2,
            activated: true,
            billOtherOrganizationBu: false,
            name: 'Orga test',
            address: '7 allée de Cérès',
            postalCode: '67200',
            city: 'Strasbourg',
            enableCallForFunds: false,
            notifyCollaborators: false,
            country: 'FR',
            mainContact: {
                id: 687368,
                email: 'regis.lutter+test@chargemap.com',
                firstName: 'Régis',
                lastName: 'Lutter',
                phone: ''
            },
            access: [
                Functionality.SUPERVISION,
                Functionality.FLEET,
                Functionality.OPERATOR
            ],
            role: '',
            buCount: 0,
            paymentTerm: 0,
            pipedriveId: 1,
            accountManager: null,
            paymentMethodsAvailable: [PaymentMethodsAvailable.DEBIT]
        },
        {
            id: 14,
            activated: true,
            billOtherOrganizationBu: false,
            name: 'dfvcxv',
            address: 'xcvcxv',
            postalCode: 'xcvcx',
            enableCallForFunds: false,
            notifyCollaborators: false,
            city: 'cxvcx',
            country: 'AD',
            mainContact: {
                id: 687517,
                email: 'cxvcx@gmail.com',
                firstName: 'xcvxcv',
                lastName: 'xcvcxv',
                phone: ''
            },
            access: [
                Functionality.SUPERVISION,
                Functionality.FLEET,
                Functionality.OPERATOR
            ],
            role: '',
            buCount: 0,
            paymentTerm: 0,
            pipedriveId: 1,
            accountManager: null,
            paymentMethodsAvailable: [PaymentMethodsAvailable.DEBIT]
        },
        {
            id: 19,
            activated: true,
            billOtherOrganizationBu: false,
            name: 'sadsad',
            address: 'sddad',
            enableCallForFunds: false,
            notifyCollaborators: false,
            postalCode: 'asdsa',
            city: 'sadsa',
            country: 'AI',
            mainContact: {
                id: 1898,
                email: 'adresse@chargemap.com',
                firstName: 'firstname',
                lastName: 'lastName',
                phone: ''
            },
            access: [
                Functionality.SUPERVISION,
                Functionality.FLEET,
                Functionality.OPERATOR
            ],
            role: '',
            buCount: 0,
            paymentTerm: 0,
            pipedriveId: 1,
            accountManager: null,
            paymentMethodsAvailable: [PaymentMethodsAvailable.DEBIT]
        }
    ] as Organization[] | OrganizationRight[]
}

const organizationWithBu = {
    id: 11,
    activated: true,
    name: 'Test',
    address: '999 rue test',
    postalCode: '67000',
    city: 'Strasbourg',
    country: 'France',
    enableCallForFunds: true,
    notifyCollaborators: false,
    mainContact: {
        id: 1,
        firstName: 'test',
        lastName: 'user',
        email: 'test@test.fr',
        phone: null
    },
    access: [],
    buCount: 2,
    paymentTerm: 0,
    pipedriveId: 1,
    accountManager: null,
    role: '',
    businessUnits: [
        {
            id: 1,
            name: 'businessUnit1',
            activated: true,
            roles: [],
            notifyMainContact: false,
            administrator: {
                id: 0,
                firstName: '',
                lastName: '',
                email: '',
                phone: null
            },
            paymentMethodsAvailable: []
        },
        {
            id: 2,
            name: 'businessUnit2',
            activated: true,
            roles: [],
            notifyMainContact: false,
            administrator: {
                id: 0,
                firstName: '',
                lastName: '',
                email: '',
                phone: null
            },
            paymentMethodsAvailable: []
        }
    ],
    billOtherOrganizationBu: true
} as Organization | OrganizationRight

export { organizationWithBu }

export default organizations
