import useNavigationStore from '~/store/navigation'
import useSessionStore from '~/store/session'

export default function (context) {
    const { route, error, redirect } = context
    const navigationStore = useNavigationStore()

    const isLoginRoute = /^(\/login)(.)*/.test(route.path)
    const isPasswordResetRoute = /^(\/password-reset)(.)*/.test(route.path)
    const isMileageRoute = /^(\/mileage-report)(.)*/.test(route.path)
    const isOrgaIndex = route.name === 'organizations-idOrga'
    const isBusinessUnitIndex =
        route.name === 'organizations-idOrga-business-units-idBU'
    const { user } = useSessionStore()
    const { isSuperAdmin } = useSessionStore()
    const message = 'errors.no_access'

    if (isOrgaIndex)
        return redirect(`/organizations/${route.params.idOrga}/dashboard`)

    if (isBusinessUnitIndex)
        return redirect(
            `/organizations/${route.params.idOrga}/business-units/${route.params.idBU}/dashboard`
        )

    if (
        !isLoginRoute &&
        !isPasswordResetRoute &&
        !isMileageRoute &&
        user &&
        !isSuperAdmin
    ) {
        return navigationStore
            .canAccessTo(route)
            .then((canAccessToThisRoute) => {
                if (!canAccessToThisRoute) {
                    return error({ statusCode: 403, message })
                }
            })
            .catch(() => error({ statusCode: 403, message }))
    }
}
