import { createLocalVue, shallowMount } from '@vue/test-utils'
import AppListTablePage from '@/components/UI/AppListTablePage.vue'
import basicMountWrapper from '~/utilitary/mountWrapper'

const localVue = createLocalVue()
describe('components/AppListTablePage.vue', () => {
    let wrapper: any

    beforeEach(() => {
        wrapper = shallowMount(AppListTablePage, {
            ...basicMountWrapper,
            localVue,
            slots: {
                noData: '<div></div>'
            }
        })
    })

    it('is a Vue instance', () => {
        expect(wrapper.vm).toBeTruthy()
        const component = wrapper.find('[data-testid="app-list-table-page"]')
        expect(component.exists()).toBeTruthy()
    })

    it('can show loading', async () => {
        const component = wrapper.find(
            '[data-testid="app-list-table-page-loading"]'
        )
        expect(component.element.style.getPropertyValue('display')).toEqual(
            'none'
        )

        await wrapper.setProps({
            isLoading: true
        })

        expect(component.element.style.getPropertyValue('display')).toBe('')
    })
})
