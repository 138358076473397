import { ref } from 'vue'
import { defineStore } from 'pinia'
import { Order } from '@/interfaces/order'
import { OrderState, PayloadFilters } from '~/interfaces/filters'

const useOrderStore = defineStore('order', () => {
    const orders = ref([] as Order[])
    const ordersCount = ref(null as number | null)
    const orderDetails = ref(null as Order | null)

    const fetchOrders = (
        context: any,
        payload: {
            idOrga: number | undefined
            idBU: number | undefined
            filters: PayloadFilters
        }
    ) => {
        const { filters } = payload
        const { pages, state } = filters

        const { idOrga, idBU } = payload
        const offset = (pages.index - 1) * pages.perPage || (0 as number)
        const limit = pages.perPage || 20
        const params = {
            offset,
            limit,
            cancelled: false,
            shipped: null as boolean | null,
            keyword: filters.keyword || null
        }

        if (state) {
            params.shipped = state === OrderState.PROCESSED
        }

        let url = '/fleetapi'
        if (idBU) {
            url += `/business-units/${idBU}`
        } else if (idOrga) {
            url += `/organizations/${idOrga}`
        }
        url += '/orders'

        return context.$axios
            .get(url, { params })
            .then((res: any) => {
                const result = context.$toCamel(res.data.items)
                ordersCount.value = Number(res?.headers?.['x-total-count']) || 0
                orders.value = result
                return result
            })
            .catch((e: any) => {
                throw e
            })
    }

    const fetchOrderDetails = (
        context: any,
        payload: {
            idOrga: number | undefined
            idBU: number | undefined
            orderId: string
        }
    ) => {
        const { idOrga, idBU, orderId } = payload

        let url = '/fleetapi'
        if (idBU) {
            url += `/business-units/${idBU}`
        } else if (idOrga) {
            url += `/organizations/${idOrga}`
        }
        url += `/orders/${orderId}`
        return context.$axios
            .$get(url)
            .then((res: any) => {
                const result = context.$toCamel(res)
                orderDetails.value = result
                return result
            })
            .catch((e: any) => {
                const defaultError = e.message
                if (e?.response?.status === 400) {
                    const reason = e.response.data?.reason ?? defaultError
                    throw new Error(reason)
                } else {
                    throw new Error(defaultError)
                }
            })
    }

    const getNumberPass = (context: any, invoiceNumber: string) => {
        const url = `/fleetapi/orders/${invoiceNumber}`
        return context.$axios
            .$get(url)
            .then((res: any) => context.$toCamel(res))
            .catch((e: any) => {
                throw e
            })
    }

    const resetOrder = (context: any, invoiceNumber: string | null) => {
        const url = '/fleetapi/orders/reset'
        return context.$axios
            .$post(url, { invoice_no: invoiceNumber })
            .then((res: any) => context.$toCamel(res))
            .catch((e: any) => {
                throw e
            })
    }

    const postOrderPass = (
        context: any,
        payload: {
            invoiceNumber: string
            passNumber: string
        }
    ) => {
        const { invoiceNumber, passNumber } = payload
        const params = { invoice_no: invoiceNumber, serial_no: passNumber }
        const url = '/fleetapi/orders/passes'
        return context.$axios
            .$post(url, params)
            .then((res: any) => context.$toCamel(res))
            .catch((e: any) => {
                const defaultError = 'Sorry, something went wrong'
                if (e?.response?.status === 400) {
                    const reason = e.response.data?.reason ?? defaultError
                    throw new Error(reason)
                } else {
                    throw new Error(defaultError)
                }
            })
    }

    const patchOrder = (
        context: any,
        payload: { invoiceNumber: string | null; trackingNumber: string }
    ) => {
        const { invoiceNumber, trackingNumber } = payload
        const url = `/fleetapi/orders/${invoiceNumber}`
        return context.$axios
            .$patch(url, { tracking_number: trackingNumber })
            .then((res: any) => context.$toCamel(res))
            .catch((e: any) => {
                throw e
            })
    }

    return {
        orders,
        ordersCount,
        orderDetails,
        fetchOrders,
        fetchOrderDetails,
        getNumberPass,
        resetOrder,
        postOrderPass,
        patchOrder
    }
})

type OrderStore = Omit<
    ReturnType<typeof useOrderStore>,
    keyof ReturnType<typeof defineStore>
>

export default useOrderStore

export type { OrderStore }
