import { createLocalVue, shallowMount } from '@vue/test-utils'
import AppCarousel from '@/components/UI/AppCarousel.vue'

// Mocks
import { TypeSlideCarousel } from '~/interfaces/other'
import basicMountWrapper from '~/utilitary/mountWrapper'

const localVue = createLocalVue()

const propsData = {
    listSlides: [
        {
            show: true,
            img: 'TeslaCB',
            title: 'title',
            body: 'text'
        },
        {
            show: false,
            img: 'TeslaCB',
            title: 'title',
            body: 'text'
        },
        {
            show: false,
            img: 'TeslaCB',
            title: 'title',
            body: 'text'
        }
    ]
}
const $emit = jest.fn()

describe('components/UI/AppCarousel.vue', () => {
    let wrapper: any

    beforeEach(() => {
        wrapper = shallowMount(AppCarousel, {
            ...basicMountWrapper,
            localVue,
            propsData,
            mocks: {
                ...basicMountWrapper.mocks,
                $emit
            },
            data() {
                return {
                    currentIndex: 0,
                    timeTotal: 300,
                    timePhase1: 10
                }
            }
        })
    })

    it('is a Vue instance', () => {
        expect(wrapper.vm).toBeTruthy()
        expect(wrapper.vm.currentIndex).toBe(0)
    })

    describe('updateShowClass', () => {
        it('can call $emit', () => {
            wrapper.vm.updateShowClass(
                1,
                true,
                TypeSlideCarousel.NEXT,
                'updateShowClass'
            )
            expect($emit).toHaveBeenCalledWith('updateShowClass', {
                index: 1,
                type: TypeSlideCarousel.NEXT,
                value: true
            })
        })
    })

    describe('getIndexSecondSlide', () => {
        it('can getIndexSecondSlide with NEXT', () => {
            expect(wrapper.vm.getIndexSecondSlide(TypeSlideCarousel.NEXT)).toBe(
                1
            )
        })

        it('can getIndexSecondSlide with PREV', () => {
            expect(wrapper.vm.getIndexSecondSlide(TypeSlideCarousel.PREV)).toBe(
                2
            )
        })

        it('can getIndexSecondSlide with NEXT and currentIndex is last element', () => {
            wrapper.vm.currentIndex = 2
            expect(wrapper.vm.getIndexSecondSlide(TypeSlideCarousel.NEXT)).toBe(
                0
            )
        })

        it('can getIndexSecondSlide with PREV and currentIndex is last element', () => {
            wrapper.vm.currentIndex = 2
            expect(wrapper.vm.getIndexSecondSlide(TypeSlideCarousel.PREV)).toBe(
                1
            )
        })
    })

    describe('animationPhaseOne', () => {
        beforeEach(() => {
            jest.spyOn(wrapper.vm, 'updateShowClass')
            jest.spyOn(wrapper.vm, 'getIndexSecondSlide')
            jest.spyOn(wrapper.vm, 'sleep')
        })

        it('can run animationPhaseOne with Next', async () => {
            wrapper.vm.getIndexSecondSlide = jest.fn().mockReturnValue(1)
            wrapper.vm.animationPhaseOne(TypeSlideCarousel.NEXT)
            expect(wrapper.vm.updateShowClass).toHaveBeenCalledWith(
                1,
                true,
                TypeSlideCarousel.NEXT,
                'updateShowClassPrevNext'
            )
            expect(wrapper.vm.sleep).toHaveBeenCalledWith(
                wrapper.vm.timePhaseOne
            )
            await new Promise((resolve) =>
                setTimeout(resolve, wrapper.vm.timePhaseOne)
            )
            expect(wrapper.vm.updateShowClass).toHaveBeenCalledWith(
                0,
                true,
                TypeSlideCarousel.NEXT,
                'updateShowClass'
            )
            expect(wrapper.vm.updateShowClass).toHaveBeenCalledWith(
                1,
                true,
                TypeSlideCarousel.NEXT,
                'updateShowClass'
            )
        })

        it('can run animationPhaseOne with Prev', async () => {
            wrapper.vm.getIndexSecondSlide = jest.fn().mockReturnValue(2)
            wrapper.vm.animationPhaseOne(TypeSlideCarousel.PREV)
            expect(wrapper.vm.updateShowClass).toHaveBeenCalledWith(
                2,
                true,
                TypeSlideCarousel.PREV,
                'updateShowClassPrevNext'
            )
            expect(wrapper.vm.sleep).toHaveBeenCalledWith(
                wrapper.vm.timePhaseOne
            )
            await new Promise((resolve) =>
                setTimeout(resolve, wrapper.vm.timePhaseOne)
            )
            expect(wrapper.vm.updateShowClass).toHaveBeenCalledWith(
                0,
                true,
                TypeSlideCarousel.PREV,
                'updateShowClass'
            )
            expect(wrapper.vm.updateShowClass).toHaveBeenCalledWith(
                2,
                true,
                TypeSlideCarousel.PREV,
                'updateShowClass'
            )
        })
    })

    describe('animationPhaseTwo', () => {
        beforeEach(() => {
            jest.spyOn(wrapper.vm, 'updateShowClass')
        })

        it('can run animationPhaseOne with Next', () => {
            wrapper.vm.animationPhaseTwo(TypeSlideCarousel.NEXT)
            wrapper.props().listSlides.forEach((_: any, index: number) => {
                expect(wrapper.vm.updateShowClass).toHaveBeenCalledWith(
                    index,
                    false,
                    TypeSlideCarousel.NEXT,
                    'updateShowClass'
                )
                expect(wrapper.vm.updateShowClass).toHaveBeenCalledWith(
                    index,
                    false,
                    TypeSlideCarousel.NEXT,
                    'updateShowClassPrevNext'
                )
            })
        })

        it('can run animationPhaseOne with Prev', () => {
            wrapper.vm.animationPhaseTwo(TypeSlideCarousel.PREV)
            wrapper.props().listSlides.forEach((_: any, index: number) => {
                expect(wrapper.vm.updateShowClass).toHaveBeenCalledWith(
                    index,
                    false,
                    TypeSlideCarousel.PREV,
                    'updateShowClass'
                )
                expect(wrapper.vm.updateShowClass).toHaveBeenCalledWith(
                    index,
                    false,
                    TypeSlideCarousel.PREV,
                    'updateShowClassPrevNext'
                )
            })
        })
    })

    describe('changeSlide', () => {
        beforeEach(() => {
            jest.spyOn(wrapper.vm, 'setInterval')
            jest.spyOn(wrapper.vm, 'animationPhaseOne')
            jest.spyOn(wrapper.vm, 'animationPhaseTwo')
            jest.spyOn(wrapper.vm, 'sleep')
        })

        it('can change slide with Next', async () => {
            wrapper.vm.changeSlide(TypeSlideCarousel.NEXT)
            expect(wrapper.vm.setInterval).toHaveBeenCalled()
            expect(wrapper.vm.animationPhaseOne).toHaveBeenCalledWith(
                TypeSlideCarousel.NEXT
            )
            await new Promise((resolve) =>
                setTimeout(resolve, wrapper.vm.timePhaseOne)
            )
            expect(wrapper.vm.sleep).toHaveBeenCalledWith(
                wrapper.vm.timeTotal - wrapper.vm.timePhaseOne
            )
            await new Promise((resolve) =>
                setTimeout(
                    resolve,
                    wrapper.vm.timeTotal - wrapper.vm.timePhaseOne
                )
            )
            expect(wrapper.vm.animationPhaseTwo).toHaveBeenCalledWith(
                TypeSlideCarousel.NEXT
            )
            expect(wrapper.vm.currentIndex).toBe(1)
        })

        it('can change slide with Prev', async () => {
            wrapper.vm.changeSlide(TypeSlideCarousel.PREV)
            expect(wrapper.vm.setInterval).toHaveBeenCalled()
            expect(wrapper.vm.animationPhaseOne).toHaveBeenCalledWith(
                TypeSlideCarousel.PREV
            )
            await new Promise((resolve) =>
                setTimeout(resolve, wrapper.vm.timePhaseOne)
            )
            expect(wrapper.vm.sleep).toHaveBeenCalledWith(
                wrapper.vm.timeTotal - wrapper.vm.timePhaseOne
            )
            await new Promise((resolve) =>
                setTimeout(
                    resolve,
                    wrapper.vm.timeTotal - wrapper.vm.timePhaseOne
                )
            )
            expect(wrapper.vm.animationPhaseTwo).toHaveBeenCalledWith(
                TypeSlideCarousel.PREV
            )
            expect(wrapper.vm.currentIndex).toBe(2)
        })

        it('can change slide with NEXT and currentIndex is last element', async () => {
            wrapper.vm.currentIndex = 2
            wrapper.vm.changeSlide(TypeSlideCarousel.NEXT)
            expect(wrapper.vm.setInterval).toHaveBeenCalled()
            expect(wrapper.vm.animationPhaseOne).toHaveBeenCalledWith(
                TypeSlideCarousel.NEXT
            )
            await new Promise((resolve) =>
                setTimeout(resolve, wrapper.vm.timePhaseOne)
            )
            expect(wrapper.vm.sleep).toHaveBeenCalledWith(
                wrapper.vm.timeTotal - wrapper.vm.timePhaseOne
            )
            await new Promise((resolve) =>
                setTimeout(
                    resolve,
                    wrapper.vm.timeTotal - wrapper.vm.timePhaseOne
                )
            )
            expect(wrapper.vm.animationPhaseTwo).toHaveBeenCalledWith(
                TypeSlideCarousel.NEXT
            )
            expect(wrapper.vm.currentIndex).toBe(0)
        })
    })
})
