import { defineStore } from 'pinia'
import { AxiosError } from 'axios'
import { ref } from 'vue'
import { Contract, ContractDTO, Feature, Plan } from '~/interfaces/flaggy'
import { CancelContractType } from '~/interfaces/organization'
import { toSnake } from '~/plugins/json/utils'

const useFlaggyStore = defineStore('flaggy', () => {
    const adminBlocked = ref(false as boolean)
    const plan = ref('' as string)
    const idOrgaContract = ref(0 as number)
    const contractId = ref(0 as number)
    const renewableDate = ref('' as string | null)
    const renewable = ref(true as boolean)
    const date = ref(null as string | null)
    const dateDeleted = ref(null as string | null)
    const prices = ref({
        passOrder: 0,
        passOrderInternational: 0,
        passLicense: {
            annual: 0,
            prorata: 0
        },
        chargeBoxLicense: {
            annual: 0,
            prorata: 0
        },
        chargeBoxRebillingLicense: {
            annual: 0,
            prorata: 0
        },
        chargeBoxDomesticLicense: {
            annual: 0,
            prorata: 0
        },
        simLicense: {
            annual: 0,
            prorata: 0
        },
        overstayParkingFeesLicense: {
            annual: 0,
            prorata: 0
        }
    })
    const features = ref({
        multipleBus: {
            adminReadonly: false,
            activated: false
        },
        blockingPass: {
            adminReadonly: false,
            activated: false
        },
        callForFunds: {
            adminReadonly: false,
            activated: false
        },
        callForFundsTab: {
            adminReadonly: false,
            activated: false
        },
        bankTransfer: {
            adminReadonly: false,
            activated: false
        },
        creditCard: {
            adminReadonly: false,
            activated: false
        },
        costAllocation: {
            adminReadonly: false,
            activated: false
        },
        debit: {
            adminReadonly: false,
            activated: false
        },
        export: {
            adminReadonly: false,
            activated: false
        },
        import: {
            adminReadonly: false,
            activated: false
        },
        billOtherBus: {
            adminReadonly: false,
            activated: false
        },
        passesQuotaReachedAlert: {
            adminReadonly: false,
            activated: false
        },
        orderNumber: {
            adminReadonly: false,
            activated: false
        },
        canEditPrices: {
            adminReadonly: false,
            activated: false
        },
        multipleUsers: {
            adminReadonly: false,
            activated: false
        },
        orders: {
            adminReadonly: false,
            activated: false
        },
        passActivation: {
            adminReadonly: false,
            activated: false
        },
        externalApiConnection: {
            adminReadonly: false,
            activated: false
        },
        overstayParkingFees: {
            adminReadonly: false,
            activated: false
        },
        controlledEnergyTariff: {
            adminReadonly: false,
            activated: false
        },
        advancedReducedTariff: {
            adminReadonly: false,
            activated: false
        },
        mileageReports: {
            adminReadonly: false,
            activated: false
        }
    } as Record<string, Feature>)
    const options = ref({
        callForFunds: false,
        billOtherBus: false
    } as Record<string, boolean>)

    const getContractRenewalDate = () => renewableDate.value || ''

    const getContractDate = () => date.value || ''

    const getContractPrices = () => {
        return {
            passOrder: prices.value.passOrder / 100,
            passOrderInternational: prices.value.passOrderInternational / 100,
            passLicense: {
                annual: prices.value.passLicense.annual / 100,
                prorata: prices.value.passLicense.prorata / 100
            },
            chargeBoxLicense: {
                annual: prices.value.chargeBoxLicense.annual / 100,
                prorata: prices.value.chargeBoxLicense.prorata / 100
            },
            chargeBoxRebillingLicense: {
                annual: prices.value.chargeBoxRebillingLicense.annual / 100,
                prorata: prices.value.chargeBoxRebillingLicense.prorata / 100
            },
            chargeBoxDomesticLicense: {
                annual: prices.value.chargeBoxDomesticLicense.annual / 100,
                prorata: prices.value.chargeBoxDomesticLicense.prorata / 100
            },
            simLicense: {
                annual: prices.value.simLicense.annual / 100,
                prorata: prices.value.simLicense.prorata / 100
            },
            overstayParkingFeesLicense: {
                annual: prices.value.overstayParkingFeesLicense.annual / 100,
                prorata: prices.value.overstayParkingFeesLicense.prorata / 100
            }
        }
    }

    const hasFeatureActivated = (feature: string) =>
        features.value?.[feature]?.activated || false

    const hasFeatureReadOnly = (feature: string) =>
        features.value?.[feature]?.adminReadonly || false

    const hasOptionActivated = (configuration: string) =>
        options.value[configuration] || false

    const hasContract = () => !!date.value

    const setContract = (contractInfo: Contract) => {
        idOrgaContract.value = contractInfo.organizationId
        contractId.value = contractInfo.id
        plan.value = contractInfo.package
        features.value = contractInfo.features
        options.value = contractInfo.options
        prices.value = contractInfo.prices
        date.value = contractInfo.date
        renewableDate.value = contractInfo.renewableDate || ''
        renewable.value = contractInfo.renewable || false
        dateDeleted.value = contractInfo.dateDeleted
        adminBlocked.value = contractInfo.adminBlocked
    }

    const fetchContract = (context: any, idOrga: number) => {
        if (!idOrga) return

        plan.value = Plan.LEGACY
        return context.$axios
            .$get(`/flaggyapi/organizations/${idOrga}/contract`)
            .then((contractDTO: ContractDTO) => {
                setContract(context.$toCamel(contractDTO) as Contract)
            })
            .catch((e: AxiosError) => {
                throw e
            })
    }

    const updateFeature = (
        context: any,
        payload: {
            idOrga: number
            feature: string
            value: boolean
        }
    ) => {
        const { idOrga, feature, value } = payload
        return context.$axios
            .$patch(
                `/flaggyapi/organizations/${idOrga}/features/${toSnake(
                    feature
                )}`,
                { activated: value }
            )
            .then((contractDTO: ContractDTO) => {
                setContract(context.$toCamel(contractDTO) as Contract)
            })
            .catch((e: AxiosError) => {
                throw e
            })
    }

    const updateOption = (
        context: any,
        payload: {
            idOrga: number
            option: string
            value: boolean
        }
    ) => {
        const { idOrga, option, value } = payload
        return context.$axios
            .$patch(
                `/flaggyapi/organizations/${idOrga}/options/${toSnake(option)}`,
                { activated: value }
            )
            .then((contractDTO: ContractDTO) => {
                setContract(context.$toCamel(contractDTO) as Contract)
            })
            .catch((e: AxiosError) => {
                throw e
            })
    }

    const updateContract = (
        context: any,
        payload: {
            idOrga: string
            property: string
            value: number | string
        }
    ) => {
        const { idOrga, property, value } = payload
        let params = {
            pass_order_price: prices.value.passOrder,
            pass_order_international_price: prices.value.passOrderInternational,
            pass_license_price: prices.value.passLicense.annual,
            charge_box_license_price: prices.value.chargeBoxLicense.annual,
            sim_license_price: prices.value.simLicense.annual,
            charge_box_rebilling_license_price:
                prices.value.chargeBoxRebillingLicense.annual,
            charge_box_domestic_license_price:
                prices.value.chargeBoxDomesticLicense.annual,
            date: date.value,
            renewable: renewable.value,
            renewable_date: renewableDate.value
        }

        params = {
            ...params,
            [property]: value,
            renewable: property === 'date' ? true : renewable.value
        }
        return context.$axios
            .$patch(
                `/flaggyapi/organizations/${idOrga}/contract/${contractId.value}`,
                params
            )
            .then((contractDTO: ContractDTO) => {
                const contractInfo = context.$toCamel(contractDTO)
                setContract(contractInfo as Contract)
            })
            .catch((e: AxiosError) => {
                throw e
            })
    }

    const cancelContract = (
        context: any,
        idOrga: string,
        cancelContractType: CancelContractType
    ) => {
        const params = {
            pass_order_price: prices.value.passOrder,
            pass_order_international_price: prices.value.passOrderInternational,
            pass_license_price: prices.value.passLicense.annual,
            charge_box_license_price: prices.value.chargeBoxLicense.annual,
            sim_license_price: prices.value.simLicense.annual,
            charge_box_rebilling_license_price:
                prices.value.chargeBoxRebillingLicense.annual,
            charge_box_domestic_license_price:
                prices.value.chargeBoxDomesticLicense.annual,
            date:
                cancelContractType === CancelContractType.IMMEDIATE
                    ? null
                    : date.value,
            renewable: false
        }

        return context.$axios
            .$patch(
                `/flaggyapi/organizations/${idOrga}/contract/${contractId.value}`,
                params
            )
            .then((contractDTO: ContractDTO) => {
                setContract(context.$toCamel(contractDTO) as Contract)
            })
            .catch((e: AxiosError) => {
                throw e
            })
    }

    const blockContract = (context: any, idOrga: number, block: boolean) => {
        return context.$axios
            .$patch(`/flaggyapi/organizations/${idOrga}/temporary-block`, {
                block
            })
            .then((contractDTO: ContractDTO) => {
                setContract(context.$toCamel(contractDTO) as Contract)
            })
            .catch((e: AxiosError) => {
                throw e
            })
    }

    return {
        adminBlocked,
        plan,
        idOrgaContract,
        contractId,
        renewableDate,
        renewable,
        date,
        dateDeleted,
        prices,
        features,
        options,
        getContractRenewalDate,
        getContractDate,
        getContractPrices,
        hasFeatureActivated,
        hasFeatureReadOnly,
        hasOptionActivated,
        hasContract,
        setContract,
        fetchContract,
        updateFeature,
        updateOption,
        updateContract,
        cancelContract,
        blockContract
    }
})

type FlaggyStore = Omit<
    ReturnType<typeof useFlaggyStore>,
    keyof ReturnType<typeof defineStore>
>

export default useFlaggyStore

export type { FlaggyStore }
