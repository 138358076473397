import { createLocalVue, shallowMount } from '@vue/test-utils'
import MenuSection from '@/components/UI/menu/MenuSection.vue'

// Mocks
import basicMountWrapper from '~/utilitary/mountWrapper'

const localVue = createLocalVue()

const $emit = jest.fn()

const propsData = {
    id: 'testId',
    header: { title: 'menu.organization.title', icon: 'IconAdmin' },
    listLinks: [
        {
            title: 'dashboard',
            icon: 'IconDashboard',
            url: '/organization/3/dashboard',
            authorized: true
        },
        {
            title: 'menu.businessunits',
            icon: 'IconBusiness',
            url: '/organization/3/business-units'
        },
        {
            title: 'menu.users',
            icon: 'IconUsers',
            url: '/organization/3/users',
            authorized: false
        },
        {
            title: 'menu.orders',
            icon: 'IconBills',
            url: '/organization/3/orders-bills',
            authorized: false
        }
    ],
    selectedMenu: 'testId'
}
const linksTrue = [
    {
        title: 'dashboard',
        icon: 'IconDashboard',
        url: '/organization/3/dashboard',
        authorized: true
    },
    {
        title: 'menu.businessunits',
        icon: 'IconBusiness',
        url: '/organization/3/business-units'
    }
]

describe('components/UI/menu/MenuSection.vue', () => {
    let wrapper: any

    beforeEach(() => {
        wrapper = shallowMount(MenuSection, {
            ...basicMountWrapper,
            localVue,
            mocks: {
                ...basicMountWrapper.mocks,
                $emit
            },
            propsData
        })
    })

    afterEach(() => {
        jest.clearAllMocks()
    })

    it('is a Vue instance', () => {
        expect(wrapper.vm).toBeTruthy()
        expect(wrapper.vm.id).toBe('testId')
        expect(wrapper.vm.header).toEqual(propsData.header)
        expect(wrapper.vm.listLinks).toEqual(propsData.listLinks)
        expect(wrapper.vm.authorized).toBeTruthy()
        expect(wrapper.vm.linksAuthorized).toEqual(linksTrue)
    })

    it('closeMobileMenu', () => {
        expect($emit).not.toHaveBeenCalled()
        wrapper.vm.closeMobileMenu()
        expect($emit).toHaveBeenCalledWith('closeMobileMenu')
    })
})
