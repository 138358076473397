import { DeliveryPointPeriodType } from '~/interfaces/deliveryPoint'

const deliveryPoint = {
    id: 24,
    businessUnitId: 687761,
    identifier: '177636726638',
    name: 'deliveryName',
    comment: '',
    maxPower: 100,
    tariff: {
        id: 1936,
        businessUnitId: 1069763,
        deliveryPointId: 154,
        type: DeliveryPointPeriodType.REDUCED,
        periods: [
            {
                name: 'period 1',
                startDate: '01-01',
                endDate: '12-31',
                timeRanges: [
                    {
                        startTime: '13:36',
                        endTime: '08:01',
                        price: '0.002'
                    },
                    {
                        startTime: '08:02',
                        endTime: '13:35',
                        price: '0.001'
                    }
                ]
            }
        ],
        billEnergy: false,
        billedEnergyMargin: 100,
        dateCreated: '2024-05-27T13:20:43+00:00'
    },
    zonesCount: 1,
    dateDeleted: null
}

export default deliveryPoint
