import useFlaggyStore from '~/store/flaggy'

export default function (context) {
    const { error } = context
    const message = 'errors.no_access'

    if (!useFlaggyStore().hasFeatureActivated('orders')) {
        return error({ statusCode: 403, message })
    }
}
