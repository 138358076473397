import { ref } from 'vue'
import { defineStore } from 'pinia'
import { CallForFund } from '@/interfaces/callForFunds'
import { CallForFundFilter } from '~/interfaces/filters'

const useCallForFundsStore = defineStore('callForFunds', () => {
    const callForFunds = ref<CallForFund[]>([])
    const callForFundsCount = ref<number | null>(null)

    const setCallForFunds = (newCallForFunds: CallForFund[]) => {
        callForFunds.value = newCallForFunds
    }

    const setCallForFundsCount = (count: number) => {
        callForFundsCount.value = count
    }

    const fetchCallForFunds = (
        context: any,
        payload: {
            idOrga: number | string
            idBU: number | string
            filters: CallForFundFilter
        }
    ) => {
        const { filters } = payload
        const { pages, minStartDate, state, keyword } = filters
        const { idOrga, idBU } = payload
        const offset = (pages.index - 1) * pages.perPage || 0
        const limit = pages.perPage || 20
        const params = {
            offset,
            limit,
            min_start_date: minStartDate || null,
            state: state || null,
            keyword
        }
        let url = `refundyapi`
        if (idOrga) {
            url += `/organizations/${idOrga}`
        }
        if (idBU) {
            url += `/business-units/${idBU}`
        }
        url += `/calls_for_funds`

        return context.$axios
            .get(url, { params })
            .then((res: any) => {
                const result = context.$toCamel(res.data)
                setCallForFunds(result.items)
                const count = Number(res.headers['x-total-count'])
                setCallForFundsCount(count)
                return result
            })
            .catch((e: any) => {
                throw e
            })
    }

    const generateArchive = (
        context: any,
        payload: {
            idOrga: number | string
            idBU: number | string
            numberCallForFunds: number | string
        }
    ) => {
        const { idOrga, idBU, numberCallForFunds } = payload
        return context.$axios
            .get(
                `refundyapi/organizations/${idOrga}/business-units/${idBU}/calls_for_funds/${numberCallForFunds}/request_archive`
            )
            .catch((e: any) => {
                throw e
            })
    }

    return {
        setCallForFunds,
        setCallForFundsCount,
        fetchCallForFunds,
        generateArchive,
        callForFunds,
        callForFundsCount
    }
})

type CallForFundsStore = Omit<
    ReturnType<typeof useCallForFundsStore>,
    keyof ReturnType<typeof defineStore>
>

export default useCallForFundsStore

export type { CallForFundsStore }
