import { createLocalVue, shallowMount } from '@vue/test-utils'
import AppCommentSection from '@/components/UI/detailSection/AppCommentSection.vue'
import basicMountWrapper from '~/utilitary/mountWrapper'

const localVue = createLocalVue()

const $emit = jest.fn()
describe('components/UI/AppCommentSection.vue', () => {
    let wrapper: any

    beforeEach(() => {
        wrapper = shallowMount(AppCommentSection, {
            ...basicMountWrapper,
            localVue,
            propsData: {
                value: 'test'
            },
            mocks: {
                ...basicMountWrapper.mocks,
                $emit
            }
        })
    })

    it('is a Vue instance', () => {
        expect(wrapper.vm).toBeTruthy()
        const component = wrapper.find('[data-testid="app-comment-section"]')
        expect(component.exists()).toBeTruthy()
    })

    describe('computed', () => {
        it('can return maxCharAvailable', async () => {
            await wrapper.setProps({
                value: null
            })
            expect(wrapper.vm.maxCharAvailable).toBe(500)

            await wrapper.setProps({
                value: 'JeSuisUnTest'
            })
            expect(wrapper.vm.maxCharAvailable).toBe(488)

            await wrapper.setProps({
                value: 'JeSuisUnTestJeSuisUnTestJeSuisUnTestJeSuisUnTestJeSuisUnTestJeSuisUnTestJeSuisUnTestJeSuisUnTestJeSuisUnTestJeSuisUnTestJeSuisUnTestJeSuisUnTestJeSuisUnTestJeSuisUnTestJeSuisUnTestJeSuisUnTestJeSuisUnTestJeSuisUnTestJeSuisUnTestJeSuisUnTestJeSuisUnTestJeSuisUnTestJeSuisUnTestJeSuisUnTestJeSuisUnTestJeSuisUnTestJeSuisUnTestJeSuisUnTestJeSuisUnTestJeSuisUnTestJeSuisUnTestJeSuisUnTestJeSuisUnTestJeSuisUnTestJeSuisUnTestJeSuisUnTestJeSuisUnTestJeSuisUnTestJeSuisUnTestJeSuisUnTestJeSuisUnTestJeSuisUnTestJeSuisUnTestJeSuisUnTest'
            })
            expect(wrapper.vm.maxCharAvailable).toEqual(-28)
        })

        it('can return tooMuchChar', async () => {
            await wrapper.setProps({
                value: 'JeSuisUnTest'
            })
            expect(wrapper.vm.tooMuchChar).toBeFalsy()

            await wrapper.setProps({
                value: 'JeSuisUnTestJeSuisUnTestJeSuisUnTestJeSuisUnTestJeSuisUnTestJeSuisUnTestJeSuisUnTestJeSuisUnTestJeSuisUnTestJeSuisUnTestJeSuisUnTestJeSuisUnTestJeSuisUnTestJeSuisUnTestJeSuisUnTestJeSuisUnTestJeSuisUnTestJeSuisUnTestJeSuisUnTestJeSuisUnTestJeSuisUnTestJeSuisUnTestJeSuisUnTestJeSuisUnTestJeSuisUnTestJeSuisUnTestJeSuisUnTestJeSuisUnTestJeSuisUnTestJeSuisUnTestJeSuisUnTestJeSuisUnTestJeSuisUnTestJeSuisUnTestJeSuisUnTestJeSuisUnTestJeSuisUnTestJeSuisUnTestJeSuisUnTestJeSuisUnTestJeSuisUnTestJeSuisUnTestJeSuisUnTestJeSuisUnTest'
            })
            expect(wrapper.vm.maxCharAvailable).toBeTruthy()
        })
    })
})
