import { createLocalVue, shallowMount } from '@vue/test-utils'
import IconChargingInactivity from '@/components/UI/icons/IconChargingInactivity.vue'

// Mocks
import basicMountWrapper from '~/utilitary/mountWrapper'

const localVue = createLocalVue()

describe('components/UI/IconChargingInactivity.vue', () => {
    let wrapper: any

    beforeEach(() => {
        wrapper = shallowMount(IconChargingInactivity, {
            ...basicMountWrapper,
            localVue
        })
    })

    it('is a Vue instance', () => {
        expect(wrapper.vm).toBeTruthy()
    })

    it('should manage charging state', async () => {
        let isCharging = wrapper.find('.icon__point--charging')
        expect(isCharging.exists()).toBeFalsy()
        await wrapper.setProps({ isCharging: true })
        await localVue.nextTick()
        isCharging = wrapper.find('.icon__point--charging')
        expect(isCharging.exists()).toBeTruthy()
    })
})
