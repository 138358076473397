import { ref, computed } from 'vue'
import { Route } from 'vue-router/types'
import { defineStore } from 'pinia'
import useOrganizationStore from './organization'
import useBusinessUnitStore from './businessunit'
import useUserStore from './user'
import {
    BusinessUnitRight,
    OrganizationRight,
    UserToken
} from '~/interfaces/user'
import { OrganizationRoles, BusinessUnitRoles } from '~/interfaces/roles'
import { UserOrganization } from '~/interfaces/organization'

const useSessionStore = defineStore('session', () => {
    /** **  STATE VARIABLES *** */
    const user = ref<UserToken | null>(null)
    const organizationRole = ref(OrganizationRoles.MEMBER as OrganizationRoles)
    const buRoles = ref([] as BusinessUnitRoles[])
    const homepage = ref('/profile' as string)

    /** **  COMPUTED  *** */
    const hasUser = computed(() => !!user.value?.rights)

    const hasOrganization = computed(() =>
        hasUser.value ? user.value?.rights.organizations : false
    )

    const countOrganizations = () =>
        user.value && hasUser.value && hasOrganization
            ? user.value.rights.organizations.length
            : 0

    const isSuperAdmin = computed(
        () => hasUser.value && user.value?.rights.superAdmin
    )

    const isAdmin = computed(
        () =>
            !!isSuperAdmin.value ||
            organizationRole.value === OrganizationRoles.ADMIN
    )

    const isBUAdmin = computed(
        () =>
            !!isAdmin.value ||
            !!buRoles.value.includes(BusinessUnitRoles.BU_ADMIN)
    )

    const isFleetManager = computed(
        () =>
            !!isBUAdmin.value ||
            !!buRoles.value.includes(BusinessUnitRoles.FLEET_MANAGER)
    )

    const isSupervisionManager = computed(
        () =>
            !!isBUAdmin.value ||
            !!buRoles.value.includes(BusinessUnitRoles.SUPERVISOR)
    )

    const hasAccessRights = computed(
        () => !!isAdmin.value || buRoles.value.length > 0
    )

    const getOrganizationsSession = computed(() =>
        hasUser.value && user.value && hasOrganization.value
            ? user.value.rights.organizations
            : []
    )

    /** **  MUTATION  *** */
    const setHomepage = (url: string | null) => {
        if (url) {
            homepage.value = url
        } else {
            homepage.value = '/'
        }
    }

    const setOrganizations = (newOrganizations: OrganizationRight[]) => {
        if (!user.value) return
        user.value = {
            ...user.value,
            rights: {
                ...user.value.rights,
                organizations: newOrganizations
            }
        }
    }

    const pushBusinessUnit = (
        idOrga: number,
        newBusinessUnit: BusinessUnitRight
    ) => {
        if (!user.value) return

        const listOrga = user.value.rights.organizations
        const orga = {
            ...listOrga.find((o: OrganizationRight) => o.id === idOrga)
        }

        if (orga && orga.businessUnits) {
            const bu = [...orga.businessUnits]
            bu.push(newBusinessUnit)
            orga.businessUnits = bu

            user.value = {
                ...user.value,
                rights: {
                    ...user.value.rights,
                    organizations: listOrga.map((o: OrganizationRight) =>
                        o.id === idOrga ? orga : o
                    ) as OrganizationRight[]
                }
            }
        }
    }

    const updateBusinessUnit = (
        idOrga: number,
        businessUnit: BusinessUnitRight
    ) => {
        if (!user.value) return

        const listOrga = user.value.rights.organizations
        const orga = listOrga.find(
            (o: OrganizationRight) => o.id === idOrga,
            10
        )
        if (orga) {
            const bu = orga.businessUnits.find(
                (b: BusinessUnitRight) => b.id === businessUnit.id
            )
            if (bu) {
                bu.name = businessUnit.name
            }
        }
    }

    const updateOrganizationRole = () => {
        const orgaRole =
            (useOrganizationStore().organization as UserOrganization)?.role ||
            ''
        organizationRole.value = orgaRole
    }

    const updateBusinessUnitRoles = () => {
        const getBuRoles = useBusinessUnitStore().getRoles()
        buRoles.value = getBuRoles || []
    }

    /** **  ACTIONS  *** */
    const getOrDeleteSubscriptionNewsletterUser = (
        context: any,
        payload: { method: string; user?: UserToken }
    ) => {
        const userObject = payload.user || context.$auth.user

        return context.$axios[payload.method](
            '/cloudapi/newsletter/subscription'
        ).then(() => {
            if (payload.method === 'delete') {
                user.value = {
                    ...userObject,
                    isSubscribedToNewsletter: false
                }
            }
        })
    }

    const subscribedUserToNewsletter = (context: any) => {
        let userObject = user.value
        if (!user.value) userObject = context.$auth.user

        return context.$axios
            .post('/cloudapi/newsletter/subscription', {
                token: context.$auth.strategy.token.get()
            })
            .then(() => {
                user.value = {
                    ...userObject,
                    isSubscribedToNewsletter: true
                } as UserToken
            })
    }

    const setUpUser = async (
        context: any,
        payload: { user: any; rights: any; token: any; refresh_token: any }
    ) => {
        useOrganizationStore().setOrganization(null)
        useBusinessUnitStore().setBusinessUnit(null)
        const userObject = context.$toCamel(payload.user)
        userObject.color = useUserStore().getUserColor(userObject.id)

        // GET INFO ABOUT NEWSLETTER SUBSCRIPTION
        userObject.isSubscribedToNewsletter = false
        await getOrDeleteSubscriptionNewsletterUser(context, {
            method: 'get',
            user: userObject
        })
            .then(() => {
                userObject.isSubscribedToNewsletter = true
            })
            .catch(() => {})

        userObject.rights = context.$toCamel(payload.rights)
        userObject.fullName = `${userObject.firstName} ${userObject.lastName}`
        await context.$auth.setUser(userObject)
        await context.$auth.$storage.setUniversal('user', userObject)

        if (userObject.locale) {
            context.$i18n.setLocale(userObject.locale.toLowerCase())
        }

        if (payload.token && payload.refresh_token) {
            await context.$auth.setUserToken(
                payload.token.value,
                payload.refresh_token.value
            )
        }
        user.value = userObject
        return userObject
    }

    const logout = (context: any) =>
        new Promise((resolve) => {
            const isBrowser = typeof window !== 'undefined'
            if (isBrowser) {
                window?.FrontChat('shutdown')
            }
            context.$auth.$storage.removeUniversal('user')
            context.$auth.strategy.token.reset()
            context.$auth.strategy.refreshToken.reset()
            resolve(true)
        })

    const loadUser = (context: any) => {
        let userObject = context.$auth.user
        if (!userObject?.id) {
            if (process.server && context.$auth.$storage.getCookie('user')) {
                userObject = context.$auth.$storage.getCookie('user')
            } else {
                return context.$auth
                    .refreshTokens()
                    .then(async (res: any) => {
                        await setUpUser(context, res.data)
                        Promise.resolve(userObject)
                    })
                    .catch(() => {
                        logout(context)
                    })
            }
            context.$auth.setUser(userObject)
            context.$i18n.setLocale(userObject?.locale?.toLowerCase())
        }
        user.value = userObject
        return Promise.resolve(userObject)
    }

    const initSession = (context: any, route: Route | null) => {
        const organizationId = route?.params?.idOrga || null
        const idBU: string | null = route?.params?.idBU || null
        return new Promise((resolve) => {
            let homepageURL: string | null = null
            useOrganizationStore()
                .switchOrganization(context, {
                    organizationId: Number(organizationId),
                    idBU: Number(idBU)
                })
                .then(() => {
                    const organization = useOrganizationStore()
                        .organization as UserOrganization
                    if (!isSuperAdmin.value && organization) {
                        const BU = useBusinessUnitStore().businessUnit

                        if (isAdmin.value) {
                            homepageURL = `/organizations/${organization.id}`
                            if (
                                organization?.businessUnits?.length === 1 &&
                                BU
                            ) {
                                homepageURL += `/business-units/${BU.id}`
                            }
                            homepageURL += '/dashboard'
                        } else if (isBUAdmin.value) {
                            homepageURL = `/organizations/${organization.id}`
                            if (organization.businessUnits.length === 1 && BU) {
                                homepageURL += `/business-units/${BU.id}/dashboard`
                            } else if (
                                organization.businessUnits.length > 0 &&
                                organization.businessUnits[0]?.id
                            ) {
                                homepageURL += `/business-units/${organization.businessUnits[0]?.id}/dashboard`
                            } else {
                                homepageURL += `/business-units`
                            }
                        } else if (isFleetManager.value && BU) {
                            homepageURL = `/organizations/${organization.id}/business-units/${BU.id}/dashboard`
                        } else if (isSupervisionManager.value && BU) {
                            homepageURL = `/organizations/${organization.id}/business-units/${BU.id}/dashboard`
                        } else {
                            homepageURL = '/profile'
                        }
                    } else if (isSuperAdmin.value) {
                        homepageURL = '/'
                    } else {
                        homepageURL = '/profile'
                    }
                })
                .catch(() => {
                    homepageURL = null
                })
                .finally(() => {
                    setHomepage(homepageURL)
                    resolve(homepageURL)
                })
        })
    }

    return {
        user,
        organizationRole,
        buRoles,
        homepage,
        countOrganizations,
        hasUser,
        hasOrganization,
        isAdmin,
        isBUAdmin,
        isFleetManager,
        isSupervisionManager,
        hasAccessRights,
        getOrganizationsSession,
        isSuperAdmin,
        setOrganizations,
        setHomepage,
        pushBusinessUnit,
        updateBusinessUnit,
        updateOrganizationRole,
        updateBusinessUnitRoles,
        getOrDeleteSubscriptionNewsletterUser,
        subscribedUserToNewsletter,
        setUpUser,
        loadUser,
        initSession,
        logout
    }
})

type SessionStore = Omit<
    ReturnType<typeof useSessionStore>,
    keyof ReturnType<typeof defineStore>
>

export default useSessionStore
export type { SessionStore }
