import { createLocalVue, shallowMount } from '@vue/test-utils'
import AppMenu from '@/components/UI/AppMenu.vue'
// Mocks
import basicMountWrapper from '~/utilitary/mountWrapper'
import useBusinessUnitStore, { BusinessUnitStore } from '~/store/businessunit'
import useOrganizationStore, { OrganizationStore } from '~/store/organization'
import defaultUser from '~/data/user'
import { UserOrganization } from '~/interfaces/organization'
import organizations, {
    organizationWithBu
} from '~/data/cloud/organization/organizations'
import useSessionStore, { SessionStore } from '~/store/session'
import { OrganizationRight } from '~/interfaces/user'
import { BusinessUnitRoles, OrganizationRoles } from '~/interfaces/roles'

const localVue = createLocalVue()

const user = {
    ...defaultUser,
    id: 45,
    activeOrganization: {
        id: 2,
        name: 'Tsup',
        role: 'MEMBER',
        businessUnits: [] as any,
        access: ['SUPERVISION', 'FLEET', 'OPERATOR'],
        activated: true,
        address: '13 rue des Cerfeuils',
        postalCode: '67314',
        city: 'Velta',
        country: 'France',
        mainContact: {
            id: 42,
            firstName: 'Thomas',
            lastName: 'Lutz',
            email: 'thomas.lutz@test.com',
            phone: ''
        },
        buCount: 0,
        billOtherOrganizationBu: true
    } as UserOrganization,
    activeBusinessUnit: {
        id: 4,
        name: 'Business Unit Supervision',
        roles: ['SUPERVISOR']
    },
    rights: {
        organizations: [
            {
                id: 1,
                name: 'Orga',
                role: 'ADMIN',
                businessUnits: [
                    {
                        id: 1,
                        name: 'Business Unit Test',
                        roles: ['SUPERVISOR']
                    },
                    {
                        id: 3,
                        name: 'Business Unit Roxxor',
                        roles: ['FLEET_MANAGER']
                    }
                ],
                access: ['SUPERVISION', 'FLEET', 'OPERATOR']
            },
            {
                id: 2,
                name: 'Tsup',
                role: 'MEMBER',
                businessUnits: [
                    {
                        id: 2,
                        name: 'Business Unit Fleet',
                        roles: ['FLEET_MANAGER']
                    },
                    {
                        id: 4,
                        name: 'Business Unit Supervision',
                        roles: ['SUPERVISOR']
                    }
                ],
                access: ['SUPERVISION', 'FLEET', 'OPERATOR']
            } as {}
        ]
    }
}

const $auth = {
    $storage: {
        getCookie: jest.fn(() => user)
    },
    user
}

const $menu = {
    $on: jest.fn((param, func) => {
        func(false)
        return param
    })
}

describe('components/UI/AppMenu.vue', () => {
    let wrapper: any
    let businessUnitStore: BusinessUnitStore
    let organizationStore: OrganizationStore
    let sessionStore: SessionStore

    beforeEach(() => {
        businessUnitStore = useBusinessUnitStore()
        businessUnitStore.setBusinessUnit = jest.fn().mockResolvedValue({
            id: 4,
            name: 'Business Unit Supervision',
            roles: ['SUPERVISOR']
        })
        businessUnitStore.switchBusinessUnit = jest.fn().mockResolvedValue({
            id: 4,
            name: 'Business Unit Supervision',
            roles: ['SUPERVISOR']
        })
        businessUnitStore.fetchBusinessUnits = jest.fn().mockResolvedValue({
            businessUnits: [
                {
                    id: 1,
                    name: 'Orga',
                    type: 1
                },
                {
                    id: 1,
                    idOrga: 1,
                    name: 'Business Unit Test',
                    type: 2
                },
                {
                    id: 3,
                    idOrga: 1,
                    name: 'Business Unit Roxxor',
                    type: 2
                },
                {
                    id: 2,
                    name: 'Tsup',
                    type: 1
                },
                {
                    id: 2,
                    idOrga: 2,
                    name: 'Business Unit Fleet',
                    type: 2
                },
                {
                    id: 4,
                    idOrga: 2,
                    name: 'Business Unit Supervision',
                    type: 2
                }
            ]
        })

        organizationStore = useOrganizationStore()
        organizationStore.organization = user.activeOrganization
        organizationStore.switchOrganization = jest.fn().mockResolvedValue(true)

        sessionStore = useSessionStore()
        sessionStore.setHomepage('/admin/organizations')
        sessionStore.organizationRole = OrganizationRoles.ADMIN
        sessionStore.user = defaultUser

        wrapper = shallowMount(AppMenu, {
            ...basicMountWrapper,
            localVue,
            mocks: {
                ...basicMountWrapper.mocks,
                $auth,
                $device: {
                    isIos: jest.fn().mockReturnValue(false)
                },
                $menu
            }
        })
    })

    afterEach(() => {
        jest.clearAllMocks()
    })

    it('is a Vue instance', () => {
        expect(wrapper.vm).toBeTruthy()
        expect(wrapper.vm.openMenu).toBe('administration')
        expect(wrapper.vm.user).toEqual(defaultUser)
        expect(wrapper.vm.selectedMenu).toBe('administration')
        expect(wrapper.vm.displayAdministration).toBeFalsy()
        expect(wrapper.vm.canSwitchBU).toBeTruthy()
        expect($menu.$on).toHaveBeenCalledWith(
            'toggleAdministration',
            expect.any(Function)
        )
        const component = wrapper.find('[data-testid="app-menu"]')
        expect(component.exists()).toBeTruthy()
    })

    it('initialize selectedMenu', async () => {
        organizationStore.switchOrganization = jest.fn().mockResolvedValue(true)
        organizationStore.setOrganization = jest.fn().mockResolvedValue(true)
        businessUnitStore.setBusinessUnit = jest.fn().mockResolvedValue({
            id: 4,
            name: 'Business Unit Supervision',
            roles: ['SUPERVISOR']
        })

        sessionStore.user = defaultUser

        wrapper = shallowMount(AppMenu, {
            ...basicMountWrapper,
            localVue,
            mocks: {
                ...basicMountWrapper.mocks,
                $auth,
                $device: {
                    isIos: jest.fn().mockReturnValue(false)
                },
                $menu: {
                    $on: jest.fn((param, func) => {
                        func(true)
                        return param
                    })
                }
            },
            propsData: {
                openMenu: 'supervision'
            }
        })
        await localVue.nextTick()
        expect(wrapper.vm).toBeTruthy()
        expect(wrapper.vm.openMenu).toBe('supervision')
        expect(wrapper.vm.user).toEqual(defaultUser)
        expect(wrapper.vm.selectedMenu).toBe('administration')
        expect(wrapper.vm.displayAdministration).toBeTruthy()
    })

    describe('canSwitchBU', () => {
        it('true if multiple organizations', () => {
            sessionStore.user = {
                ...defaultUser,
                rights: {
                    organizations:
                        organizations.organizations as OrganizationRight[],
                    superAdmin: false
                }
            }
            expect(wrapper.vm.canSwitchBU).toBeTruthy()
        })

        it('true if multiple BU', () => {
            user.activeOrganization.businessUnits = (
                organizationWithBu as UserOrganization
            ).businessUnits
            expect(wrapper.vm.canSwitchBU).toBeTruthy()
            user.activeOrganization.businessUnits = []
        })

        it('true if admin organizations', () => {
            sessionStore.organizationRole = OrganizationRoles.ADMIN
            expect(wrapper.vm.canSwitchBU).toBeTruthy()
        })

        it('true if superadmin', () => {
            sessionStore.user = {
                ...defaultUser,
                rights: {
                    organizations: [],
                    superAdmin: true
                }
            }
            expect(wrapper.vm.canSwitchBU).toBeTruthy()
        })

        it('false in other cases', () => {
            sessionStore.organizationRole = OrganizationRoles.MEMBER
            expect(wrapper.vm.canSwitchBU).toBeFalsy()
        })
    })

    describe('businessUnitsAvailable returns', () => {
        it('all business units from all organizations', () => {
            const listBU = wrapper.vm.businessUnitsAvailable
            expect(listBU).toEqual([
                {
                    id: 11,
                    name: 'Test',
                    type: 1
                },
                {
                    id: 1,
                    idOrga: 11,
                    name: 'businessUnit1',
                    type: 2
                },
                {
                    id: 2,
                    idOrga: 11,
                    name: 'businessUnit2',
                    type: 2
                }
            ])
        })

        it('only organizations and filter BUs with search', () => {
            wrapper.vm.searchBU = 'Flee'
            const listBU = wrapper.vm.businessUnitsAvailable
            expect(listBU).toEqual([
                {
                    id: 11,
                    name: 'Test',
                    type: 1
                }
            ])
        })

        it('only organizations without business units in it', () => {
            const listBU = wrapper.vm.businessUnitsAvailable
            expect(listBU).toEqual([
                {
                    id: 11,
                    name: 'Test',
                    type: 1
                },
                {
                    id: 1,
                    idOrga: 11,
                    name: 'businessUnit1',
                    type: 2
                },
                {
                    id: 2,
                    idOrga: 11,
                    name: 'businessUnit2',
                    type: 2
                }
            ])
        })

        it('empty array, no organizations', () => {
            const copyUser = user
            copyUser.rights.organizations = []
            $auth.user = copyUser
            sessionStore.user = {
                ...defaultUser,
                rights: { organizations: [], superAdmin: false }
            }
            const listBU = wrapper.vm.businessUnitsAvailable
            expect(listBU).toEqual([])
        })
    })

    it('goToAdmin calls toggle and redirect', () => {
        const toggleSpy = jest.spyOn(wrapper.vm, 'toggleAdministration')
        expect(toggleSpy).not.toHaveBeenCalled()
        wrapper.vm.goToAdmin()
        expect(toggleSpy).toHaveBeenCalled()
        expect(wrapper.vm.$router.push).toHaveBeenCalledWith(
            '/admin/organizations'
        )
    })

    it('toggle administration menu', () => {
        expect(wrapper.vm.displayAdministration).toBeFalsy()
        wrapper.vm.toggleAdministration(true)
        expect(wrapper.vm.displayAdministration).toBeTruthy()
        wrapper.vm.toggleAdministration()
        expect(wrapper.vm.displayAdministration).toBeFalsy()
        wrapper.vm.toggleAdministration(false)
        expect(wrapper.vm.displayAdministration).toBeFalsy()
    })

    it('switch menu emits change', () => {
        expect(wrapper.emitted().change).toBeFalsy()
        const id = 'administration'
        wrapper.vm.switchMenu(id)
        expect(wrapper.vm.selectedMenu).toEqual(id)
        expect(wrapper.emitted().change[0]).toEqual([id])
    })

    it('close mobile menu to emit close', () => {
        expect(wrapper.emitted().close).toBeFalsy()
        wrapper.vm.closeMobileMenu()
        expect(wrapper.emitted().close).toBeTruthy()
    })

    describe('changeBusinessUnit', () => {
        it('can switch business unit in store', async () => {
            await wrapper.vm.changeBusinessUnit(2, 4, 2)
            expect(businessUnitStore.switchBusinessUnit).toHaveBeenCalledWith(
                expect.any(Object),
                {
                    idBU: 4,
                    idOrga: 2
                }
            )
            expect(wrapper.vm.$router.push).toHaveBeenCalledWith(
                '/organizations/2/business-units/4/dashboard'
            )
        })
        it('doesnt change business unit when error occured', async () => {
            await wrapper.vm.changeBusinessUnit(2, 4, 2)
            expect(wrapper.vm.$router.push).toHaveBeenCalledWith(
                '/organizations/2/business-units/4/dashboard'
            )
            expect(businessUnitStore.switchBusinessUnit).toHaveBeenCalledWith(
                expect.any(Object),
                { idBU: 4, idOrga: 2 }
            )
        })

        describe('change organization to call store', () => {
            beforeEach(() => {
                sessionStore.organizationRole = OrganizationRoles.MEMBER
                sessionStore.user = defaultUser
                organizationStore.switchOrganization = jest
                    .fn()
                    .mockResolvedValue({})
                businessUnitStore.setBusinessUnit = jest
                    .fn()
                    .mockResolvedValue({
                        id: 4,
                        name: 'Business Unit Supervision',
                        roles: ['SUPERVISOR']
                    })
            })

            it('should switch organization', async () => {
                await wrapper.vm.switchOrganization(3)
                expect(
                    organizationStore.switchOrganization
                ).toHaveBeenCalledWith(expect.any(Object), {
                    organizationId: 3
                })
                expect(businessUnitStore.setBusinessUnit).toHaveBeenCalledWith(
                    null
                )
                await localVue.nextTick()
                expect(wrapper.vm.$router.push).toHaveBeenCalledWith(
                    '/organizations/3/business-units'
                )
                sessionStore.organizationRole = OrganizationRoles.ADMIN
                await wrapper.vm.switchOrganization(3)
                await localVue.nextTick()
                expect(wrapper.vm.$router.push).toHaveBeenCalledWith(
                    '/organizations/3/dashboard'
                )
            })

            it('as Admin', async () => {
                sessionStore.organizationRole = OrganizationRoles.ADMIN
                wrapper.vm.switchOrganization = jest.fn()
                await wrapper.vm.changeBusinessUnit(1, 3, 2)
                expect(wrapper.vm.switchOrganization).toHaveBeenCalledWith(3)
            })

            it('as SuperAdmin', async () => {
                sessionStore.organizationRole = OrganizationRoles.ADMIN
                wrapper.vm.switchOrganization = jest.fn()
                await wrapper.vm.changeBusinessUnit(1, 3, 2)
                expect(wrapper.vm.switchOrganization).toHaveBeenCalledWith(3)
            })
            it('when an error occured', async () => {
                sessionStore.organizationRole = OrganizationRoles.ADMIN
                await wrapper.vm.changeBusinessUnit(1, 3, 2)
                expect(
                    organizationStore.switchOrganization
                ).toHaveBeenCalledWith(expect.any(Object), {
                    organizationId: 3
                })
                await localVue.nextTick()
                expect(wrapper.vm.$router.push).not.toHaveBeenCalled()
            })
            it('when an error occured with bu call', async () => {
                sessionStore.user = {
                    ...user,
                    rights: {
                        organizations: [],
                        superAdmin: true
                    }
                }
                await wrapper.vm.changeBusinessUnit(1, 3, 2)
                expect(
                    organizationStore.switchOrganization
                ).toHaveBeenCalledWith(expect.any(Object), {
                    organizationId: 3
                })
                await localVue.nextTick()
                expect(wrapper.vm.$router.push).not.toHaveBeenCalled()
            })
        })

        it('dont switch organization or business unit', async () => {
            sessionStore.user = {
                ...defaultUser,
                rights: { organizations: [], superAdmin: true }
            }
            await wrapper.vm.changeBusinessUnit(3, 3, 2)
            expect(organizationStore.switchOrganization).not.toHaveBeenCalled()
            expect(businessUnitStore.switchBusinessUnit).not.toHaveBeenCalled()
        })
    })

    it('can display admin section', () => {
        let component = wrapper.find('[data-testid="app-menu-admin-section"]')
        expect(component.exists()).toBeFalsy()

        businessUnitStore.businessUnit = null
        organizationStore.organization = null
        organizationStore.switchOrganization = jest.fn().mockResolvedValue(true)
        organizationStore.setOrganization = jest.fn().mockResolvedValue(true)
        businessUnitStore.setBusinessUnit = jest.fn().mockResolvedValue({
            id: 4,
            name: 'Business Unit Supervision',
            roles: ['SUPERVISOR']
        })

        sessionStore.user = {
            ...defaultUser,
            rights: { organizations: [], superAdmin: true }
        }

        wrapper = shallowMount(AppMenu, {
            ...basicMountWrapper,
            localVue,
            mocks: {
                ...basicMountWrapper.mocks,
                $auth,
                $device: {
                    isIos: jest.fn().mockReturnValue(false)
                },
                $menu: {
                    $on: jest.fn((param, func) => {
                        func(true)
                        return param
                    })
                }
            },
            propsData: {
                openMenu: 'supervision'
            }
        })

        component = wrapper.find('[data-testid="app-menu-admin-section"]')
        expect(component.exists()).toBeTruthy()
    })

    it('can display organisation menu', () => {
        let component = wrapper.find('[data-testid="app-menu-organisation"]')
        expect(component.exists()).toBeTruthy()

        businessUnitStore.businessUnit = null
        organizationStore.organization = null
        organizationStore.switchOrganization = jest.fn().mockResolvedValue(true)
        organizationStore.setOrganization = jest.fn().mockResolvedValue(true)
        businessUnitStore.setBusinessUnit = jest.fn().mockResolvedValue({
            id: 4,
            name: 'Business Unit Supervision',
            roles: [BusinessUnitRoles.SUPERVISOR]
        })
        sessionStore.user = {
            ...defaultUser,
            rights: {
                organizations:
                    organizations.organizations as OrganizationRight[],
                superAdmin: true
            }
        }
        wrapper = shallowMount(AppMenu, {
            ...basicMountWrapper,
            localVue,
            mocks: {
                ...basicMountWrapper.mocks,
                $auth,
                $device: {
                    isIos: jest.fn().mockReturnValue(false)
                },
                $menu: {
                    $on: jest.fn((param, func) => {
                        func(true)
                        return param
                    })
                }
            },
            propsData: {
                displaySelectBU: false,
                openMenu: 'supervision'
            }
        })

        component = wrapper.find('[data-testid="app-menu-organisation"]')
        expect(component.exists()).toBeFalsy()
    })

    it('can display super admin button', () => {
        sessionStore.user = {
            ...defaultUser,
            rights: {
                organizations:
                    organizations.organizations as OrganizationRight[],
                superAdmin: true
            }
        }
        organizationStore.organization = organizations.organizations[0]
        wrapper = shallowMount(AppMenu, {
            ...basicMountWrapper,
            localVue,
            mocks: {
                ...basicMountWrapper.mocks,
                $auth,
                $device: {
                    isIos: jest.fn().mockReturnValue(false)
                },
                $menu: {
                    $on: jest.fn((param, func) => {
                        func(true)
                        return param
                    })
                }
            },
            propsData: {
                displaySelectBU: false,
                openMenu: 'supervision'
            }
        })

        let component = wrapper.find(
            '[data-testid="app-menu-super-admin-button"]'
        )
        expect(component.exists()).toBeTruthy()

        organizationStore.organization = user.activeOrganization
        businessUnitStore.businessUnit = null
        organizationStore.switchOrganization = jest.fn().mockResolvedValue(true)
        organizationStore.setOrganization = jest.fn().mockResolvedValue(true)
        businessUnitStore.setBusinessUnit = jest.fn().mockResolvedValue(true)
        sessionStore.user = {
            ...defaultUser,
            rights: { organizations: [], superAdmin: false }
        }
        wrapper = shallowMount(AppMenu, {
            ...basicMountWrapper,
            localVue,
            mocks: {
                ...basicMountWrapper.mocks,
                $auth,
                $device: {
                    isIos: jest.fn().mockReturnValue(false)
                },
                $menu: {
                    $on: jest.fn((param, func) => {
                        func(true)
                        return param
                    })
                }
            },
            propsData: {
                openMenu: 'supervision'
            }
        })

        component = wrapper.find('[data-testid="app-menu-super-admin-button"]')
        expect(component.exists()).toBeFalsy()
    })

    it('can display select BU', async () => {
        let component = wrapper.find('[data-testid="app-menu-BU"]')
        expect(component.exists()).toBeFalsy()

        organizationStore.organization = user.activeOrganization
        businessUnitStore.businessUnit = null
        organizationStore.switchOrganization = jest.fn().mockResolvedValue(true)
        organizationStore.setOrganization = jest.fn().mockResolvedValue(true)
        businessUnitStore.setBusinessUnit = jest.fn().mockResolvedValue(true)

        wrapper = shallowMount(AppMenu, {
            ...basicMountWrapper,
            localVue,
            mocks: {
                ...basicMountWrapper.mocks,
                $auth,
                $device: {
                    isIos: jest.fn().mockReturnValue(false)
                },
                $menu: {
                    $on: jest.fn((param, func) => {
                        func(true)
                        return param
                    })
                }
            },
            propsData: {
                openMenu: 'supervision'
            }
        })

        await wrapper.setData({ displaySelectBU: true })

        component = wrapper.find('[data-testid="app-menu-BU"]')
        expect(component.exists()).toBeTruthy()
    })

    it('can return isProductionEnvironment', () => {
        expect(wrapper.vm.isProductionEnvironment).toBeFalsy()

        wrapper = shallowMount(AppMenu, {
            ...basicMountWrapper,
            localVue,
            mocks: {
                ...basicMountWrapper.mocks,
                $auth,
                $device: {
                    isIos: jest.fn().mockReturnValue(false)
                },
                $menu,
                $config: {
                    ...basicMountWrapper.mocks.$config,
                    NUXT_ENV: 'production'
                }
            }
        })
        expect(wrapper.vm.isProductionEnvironment).toBeTruthy()
    })

    it('currentLocale', () => {
        expect(wrapper.vm.currentLocale).toBe('en')

        wrapper.vm.$i18n.locale = 'fr'

        expect(wrapper.vm.currentLocale).toBe('fr')
    })
})
