import useFlaggyStore from '~/store/flaggy'
import useSessionStore from '~/store/session'

export default function (context) {
    const { route, error, redirect, $toast, i18n } = context

    const currentIdOrga = Number(route.params.idOrga)
    const hasRightContractOrganization =
        useFlaggyStore().idOrgaContract === currentIdOrga
    const message = 'errors.no_access'

    if (
        route.params.idOrga &&
        (!hasRightContractOrganization ||
            !useFlaggyStore().plan ||
            !useFlaggyStore().hasContract())
    ) {
        return useFlaggyStore()
            .fetchContract(context, currentIdOrga)
            .then(() => {
                if (!useFlaggyStore().hasContract()) {
                    const isChargemapAdmin = useSessionStore().isSuperAdmin
                    if (isChargemapAdmin) {
                        const orgaParameterRoute = `/organizations/${route.params.idOrga}/parameters`
                        const isOrgaParameterRoute =
                            route.path === orgaParameterRoute
                        if (!isOrgaParameterRoute) {
                            if ($toast && i18n && $toast.global) {
                                $toast.global?.error({
                                    title: i18n.t('errors.no-contract')
                                })
                            }
                            return redirect(
                                `${orgaParameterRoute}#functionalities`
                            )
                        }
                    } else {
                        return error({ statusCode: 402, message })
                    }
                }
            })
            .catch(() => error({ statusCode: 403, message }))
    }
}
