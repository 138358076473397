import { createLocalVue, shallowMount } from '@vue/test-utils'
import AppControlInput from '@/components/UI/AppControlInput.vue'

// Mocks
import basicMountWrapper from '~/utilitary/mountWrapper'

const localVue = createLocalVue()

describe('components/UI/AppControlInput.vue', () => {
    let wrapper: any

    beforeEach(() => {
        wrapper = shallowMount(AppControlInput, {
            ...basicMountWrapper,
            localVue
        })
    })

    it('is a Vue instance', () => {
        expect(wrapper.vm).toBeTruthy()
        expect(wrapper.vm.error).toBe('')
    })

    describe('check email input', () => {
        it('is valid', async () => {
            await wrapper.setProps({
                value: 'test@mail.com'
            })
            wrapper.vm.controlInput()
            expect(wrapper.vm.error).toBe('')
            await wrapper.setProps({
                controlType: 'email'
            })
            wrapper.vm.controlInput()
            expect(wrapper.vm.error).toBe('')
        })

        it('is invalid', async () => {
            await wrapper.setProps({
                controlType: 'email',
                value: 'text simple'
            })
            wrapper.vm.controlInput()
            expect(wrapper.vm.error).toBe('email.invalid')
        })

        it('with multiple emails', () => {
            expect(wrapper.vm.validEmail('test43@mail.o')).toBeFalsy()
            expect(wrapper.vm.validEmail('test.com')).toBeFalsy()
            expect(wrapper.vm.validEmail('test43@mail.com')).toBeTruthy()
            expect(wrapper.vm.validEmail('coucou+alias@mail.fr')).toBeTruthy()
        })
    })

    describe('check tel input', () => {
        it('is valid', async () => {
            await wrapper.setProps({
                value: '+33 6 79 91 25 49'
            })
            wrapper.vm.controlInput()
            expect(wrapper.vm.error).toBe('')
            await wrapper.setProps({
                controlType: 'tel'
            })
            wrapper.vm.controlInput()
            expect(wrapper.vm.error).toBe('')
        })

        it('is invalid', async () => {
            await wrapper.setProps({
                controlType: 'tel',
                value: '935 263223 64 949'
            })
            wrapper.vm.controlInput()
            expect(wrapper.vm.error).toBe('tel.invalid')
        })

        it('with multiple phone numbers', () => {
            expect(wrapper.vm.validTel('067 9 91 254 9')).toBeFalsy()
            expect(wrapper.vm.validTel('+33698912549')).toBeTruthy()
            expect(wrapper.vm.validTel('+1-555-532-3455')).toBeTruthy()
            expect(wrapper.vm.validTel('(555)-555-5555')).toBeTruthy()
        })
    })

    describe('check hexa input', () => {
        it('is valid', async () => {
            await wrapper.setProps({
                value: 'ABEC1334AFE'
            })
            wrapper.vm.controlInput()
            expect(wrapper.vm.error).toBe('')
            await wrapper.setProps({
                controlType: 'hexa'
            })
            wrapper.vm.controlInput()
            expect(wrapper.vm.error).toBe('')
        })

        it('is invalid', async () => {
            await wrapper.setProps({
                controlType: 'hexa',
                value: 'ABEC1334AFEX'
            })
            wrapper.vm.controlInput()
            expect(wrapper.vm.error).toBe('hexa.invalid')
        })
    })
})
