
import Vue from 'vue'
import type { PropType } from 'vue'
// @ts-ignore
import DatePicker from 'vue2-datepicker'
// @ts-ignore
import fr from 'vue2-datepicker/locale/fr'
// @ts-ignore
import de from 'vue2-datepicker/locale/de'
// @ts-ignore
import en from 'vue2-datepicker/locale/en'
import { DateTime } from 'luxon'

export default Vue.extend({
    name: 'ChargemapDatepicker',
    components: { DatePicker },
    props: {
        defaultValue: {
            type: null as unknown as PropType<
                string | Array<string> | number | null
            >,
            default: DateTime.now().toISODate()
        },
        value: {
            type: null as unknown as PropType<
                string | Array<string> | number | null
            >,
            required: true
        },
        type: {
            type: String,
            default: 'date'
        },
        isRange: {
            type: Boolean,
            default: false
        },
        placeholder: {
            type: String,
            default: ''
        },
        clearable: {
            type: Boolean,
            default: false
        },
        valueType: {
            type: String,
            default: 'YYYY-MM-DD'
        },
        format: {
            type: String,
            default: 'DD/MM/YYYY'
        },
        titleFormat: {
            type: String,
            default: 'YYYY-MM-DD'
        },
        lang: {
            type: String,
            default: 'en'
        },
        disabledDate: {
            type: Function,
            default: undefined
        }
    },
    data() {
        return {
            fr,
            de,
            en
        }
    },
    computed: {
        getLang(this: any) {
            switch (this.lang) {
                case 'fr':
                    return this.fr
                case 'de':
                    return this.de
                default:
                    return this.en
            }
        },
        getLocalFormat(this: any) {
            switch (this.lang) {
                case 'fr':
                    return 'DD/MM/YYYY'
                case 'de':
                    return 'DD.MM.YYYY'
                default:
                    return 'YYYY-MM-DD'
            }
        }
    }
})
