import { createLocalVue, shallowMount } from '@vue/test-utils'
import AppLoader from '@/components/UI/AppLoader.vue'

const localVue = createLocalVue()

describe('components/UI/AppLoader.vue', () => {
    let wrapper: any

    beforeEach(() => {
        wrapper = shallowMount(AppLoader, {
            localVue
        })
    })

    it('is a Vue instance', () => {
        expect(wrapper.vm).toBeTruthy()
    })
})
