
import Vue from 'vue'
import { mapActions, mapState } from 'pinia'
import useMobileFilterStore from '~/store/mobileFilter'

export default Vue.extend({
    name: 'AppCheckbox',
    props: {
        value: {
            type: Boolean,
            default: undefined
        }
    },
    data() {
        return {
            inputId: 'checkbox'
        }
    },
    computed: {
        ...mapState(useMobileFilterStore, ['filters']),
        BSfilter(this: any) {
            const translation = this.value ? 'disable' : 'enable'
            return {
                id: this._uid,
                name: this.$slots.label
                    ? this.$slots.label[0].text
                    : this.$tc(translation),
                value: this.value,
                type: 'AppFilterCheckbox'
            }
        },
        label(): string {
            return this.value ? 'disable' : 'enable'
        }
    },
    watch: {
        filters: {
            handler(this: any, val) {
                const filter = val.find((f: any) => f.id === this._uid)
                if (useMobileFilterStore().showBottomSheet) {
                    this.updateLocalFilter(filter)
                }
            },
            deep: true
        }
    },
    mounted(this: any) {
        this.inputId = this._uid
        this.addFilter(this.BSfilter)
    },

    beforeDestroy(this: any) {
        this.removeFilter(this._uid)
    },
    methods: {
        ...mapActions(useMobileFilterStore, [
            'addFilter',
            'removeFilter',
            'updateFilter'
        ]),
        update(this: any, value: boolean) {
            this.$emit('input', value)
            this.$emit('change', value)
            this.updateFilter({
                id: this._uid,
                value
            })
        },
        updateLocalFilter(this: any, filter: any) {
            if (filter.id === this._uid) {
                this.update(filter.value)
            }
        },
        changeValue(evt: any) {
            this.update(evt.target.checked)
        }
    }
})
