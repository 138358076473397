import { createLocalVue, shallowMount } from '@vue/test-utils'
import AppBadge from '@/components/UI/AppBadge.vue'

const localVue = createLocalVue()

describe('components/UI/AppBadge.vue', () => {
    let wrapper: any

    beforeEach(() => {
        wrapper = shallowMount(AppBadge, {
            localVue
        })
    })

    it('is a Vue instance', () => {
        expect(wrapper.vm).toBeTruthy()
        const component = wrapper.find('[data-testid="app-badge"]')
        expect(component.exists()).toBeTruthy()
    })

    it('can display indicator if props indicator is true', async () => {
        let component = wrapper.find('[data-testid="app-badge-indicator"]')
        expect(component.exists()).toBeFalsy()

        await wrapper.setProps({
            indicator: true
        })

        component = wrapper.find('[data-testid="app-badge-indicator"]')
        expect(component.exists()).toBeTruthy()
    })

    it('should test size of badge', async () => {
        await wrapper.setProps({ size: 'xs', squared: false })
        expect(wrapper.vm.sizeComputed).toBe('px-2.5')
        await wrapper.setProps({ size: 'xs', squared: true })
        expect(wrapper.vm.sizeComputed).toBe('px-2')
        await wrapper.setProps({ size: 'xxs', squared: false })
        expect(wrapper.vm.sizeComputed).toBe('px-1')
        await wrapper.setProps({ size: 'xxs', squared: true })
        expect(wrapper.vm.sizeComputed).toBe('px-0.5')
        await wrapper.setProps({ size: 'sm', squared: false })
        expect(wrapper.vm.sizeComputed).toBe('px-3 py-1')
        await wrapper.setProps({ size: 'sm', squared: true })
        expect(wrapper.vm.sizeComputed).toBe('px-2.5 py-1')
    })
})
