import { createLocalVue, shallowMount } from '@vue/test-utils'
import AppCard from '@/components/UI/AppCard.vue'

// Mocks
import basicMountWrapper from '~/utilitary/mountWrapper'

const localVue = createLocalVue()

describe('components/UI/AppCard.vue', () => {
    let wrapper: any

    beforeEach(() => {
        wrapper = shallowMount(AppCard, {
            ...basicMountWrapper,
            localVue
        })
    })

    it('is a Vue instance', () => {
        expect(wrapper.vm).toBeTruthy()
        expect(wrapper.vm.isEnd).toBeFalsy()
    })
})
