import { defineStore } from 'pinia'
import { TypeZone, Zone, ZoneJSON } from '~/interfaces/zone'
import { axiosListWrapper } from '~/utilitary/storeWrapper'
import { keysToSnake, keysToCamel } from '~/plugins/json/utils'

const useZoneStore = defineStore('zone', () => {
    const createUpdateZone = (
        context: any,
        payload: { zone: Zone; idBU: number; idZone: number | undefined }
    ) => {
        const { zone, idBU, idZone } = payload
        const newZoneFormatted: ZoneJSON = keysToSnake(zone) as ZoneJSON
        let method = 'post'
        let url = `/supervisionapi/business-units/${idBU}/zones`
        if (idZone) {
            method = 'patch'
            url += `/${idZone}`
        }
        return context.$axios[method](url, newZoneFormatted)
    }

    const fetchZones = (
        context: any,
        payload: {
            search: string
            idBU: number
            type?: TypeZone | null
            idDeliveryPoint?: number | string
        }
    ) => {
        const { search, idDeliveryPoint, idBU, type } = payload

        const params = {
            keyword: search || undefined,
            type,
            delivery_point_id: idDeliveryPoint
        }

        const url = `/supervisionapi/business-units/${idBU}/zones`
        return context.$axios
            .$get(url, { params })
            .then((res: Zone) => keysToCamel(res))
    }

    const fetchZone = (
        context: any,
        payload: { idBU: string; idZone: string }
    ) => {
        const { idBU, idZone } = payload
        const url = `/supervisionapi/business-units/${idBU}/zones/${idZone}`
        return context.$axios.$get(url).then((res: Zone) => keysToCamel(res))
    }

    const fetchDetailedZones = (
        context: any,
        payload: {
            idBU: number
            index?: number
            zoneName?: string
            zoneType: TypeZone
            isConnected?: boolean
            rebillingActivated?: boolean
            keyword?: string
            overstayParkingTariffActivated?: boolean
            hasOverstayParkingLicense?: boolean
            overstayParkingTariffRenewable?: boolean
        }
    ) => {
        const {
            index,
            idBU,
            zoneName,
            zoneType,
            rebillingActivated,
            isConnected,
            overstayParkingTariffActivated,
            hasOverstayParkingLicense,
            overstayParkingTariffRenewable,
            keyword
        } = payload

        const params = {
            type: zoneType,
            zone_name: zoneName,
            rebilling_activated: rebillingActivated,
            is_connected: isConnected,
            overstay_parking_tariff_activated: overstayParkingTariffActivated,
            has_overstay_parking_license: hasOverstayParkingLicense,
            overstay_parking_tariff_renewable: overstayParkingTariffRenewable
        }

        const url = `/supervisionapi/business-units/${idBU}/detailed_zones`
        return axiosListWrapper
            .bind(context)(url, params, {
                pages: { perPage: 20, index },
                searchField: keyword
            })
            .then((res: any) => ({
                count: res.totalCount,
                zones: res.items
            }))
    }

    const getCollaboratorZones = (
        context: any,
        payload: {
            idBU: string
            index: number
            perPage: number
            collaboratorId?: number
        }
    ) => {
        const { idBU, collaboratorId, index, perPage } = payload
        const params = {
            type: TypeZone.DOMESTIC,
            collaborator_id: collaboratorId
        }
        const url = `/supervisionapi/business-units/${idBU}/detailed_zones`
        return axiosListWrapper
            .bind(context)(url, params, {
                pages: { perPage, index },
                searchField: ''
            })
            .then((res: any) => ({
                count: res.totalCount,
                zones: res.items
            }))
    }

    const assignDeliveryPointToZone = (
        context: any,
        payload: {
            idDeliveryPoint: number | null
            idBU: number
            idZone: number
        }
    ) => {
        const { idBU, idZone, idDeliveryPoint } = payload
        const url = `/supervisionapi/business-units/${idBU}/zones/${idZone}`
        return context.$axios.patch(url, {
            delivery_point_id: idDeliveryPoint
        })
    }

    const deleteZone = (
        context: any,
        payload: {
            idBU: string
            idZone: number
        }
    ) => {
        const { idBU, idZone } = payload
        const url = `/supervisionapi/business-units/${idBU}/zones/${idZone}`
        return context.$axios.delete(url)
    }

    const fetchAddressListJAWG = (context: any, location: string) => {
        const url = '/server/getJAWGAPIKey'
        return context.$axios
            .$get(url, {
                params: {
                    text: location,
                    size: 5,
                    sources: 'oa'
                }
            })
            .then((response: { token: string }) =>
                context.$axios.$get('jawgapi/places/v1/autocomplete', {
                    params: {
                        text: location,
                        'access-token': response.token,
                        size: 5,
                        sources: 'oa'
                    }
                })
            )
            .then((geoJsonResponse: any) => {
                if (!geoJsonResponse?.features) return []
                return geoJsonResponse.features.map((suggestion: any) => ({
                    address: suggestion.properties?.name || '',
                    label: `${suggestion.properties?.name || ''}, ${
                        suggestion.properties?.postalcode || ''
                    } ${suggestion.properties?.locality || ''}`,
                    lat: suggestion.geometry.coordinates[1],
                    lng: suggestion.geometry.coordinates[0],
                    city: suggestion.properties?.locality || '',
                    postalCode: suggestion.properties?.postalcode || '',
                    countryCode: suggestion.properties?.country_code || ''
                }))
            })
            .catch((e: any) => {
                throw e
            })
    }

    return {
        createUpdateZone,
        fetchZones,
        fetchZone,
        fetchDetailedZones,
        getCollaboratorZones,
        assignDeliveryPointToZone,
        deleteZone,
        fetchAddressListJAWG
    }
})

type ZoneStore = Omit<
    ReturnType<typeof useZoneStore>,
    keyof ReturnType<typeof defineStore>
>

export default useZoneStore

export type { ZoneStore }
